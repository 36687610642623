import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import styled from "styled-components";
import route from "./routes";
import { store, persistor } from "./redux/store";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import GlobalStyle from "./styles/GlobalStyle";
import theme from "./styles/theme";
import "dayjs/locale/ko";
import { ToastContainer } from "react-toastify";
import dayjs from "dayjs";

import { PersistGate } from "redux-persist/integration/react";

import Confirm from "./components/common/Confirm";
import DatepickerModal from "./components/DatepickerModal";
import AppUpdate from "./components/AppUpdate";
import { closeConfirm, openConfirm } from "./redux/ConfirmSlice";
import { useNavigate } from "react-router-dom";

const App = () => {
    // dayjs.locale("ko");

    useEffect(() => {
        let deferredPrompt;

        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            // Update UI notify the user they can install the PWA
            // Optionally, send analytics event that PWA install promo was shown.
            console.log(`'beforeinstallprompt' event was fired.`);
        });
    }, []);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false, // default: true
            },
        },
    });

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={theme["light"]}>
                        <GlobalStyle />
                        {route()}
                        <StyledToastContainer closeButton={false} />
                        <DatepickerModal />
                        <Confirm />
                        <AppUpdate />
                    </ThemeProvider>
                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                </QueryClientProvider>
            </PersistGate>
        </Provider>
    );
};

const StyledToastContainer = styled(ToastContainer)`
    --toastify-color-dark: var(--gray-black);
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(0, -50%);

    .Toastify__toast {
        min-height: 0;
        padding: 8px 20px;
    }
    .Toastify__toast-body {
        padding: 0;

        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.01em;
    }
    .Toastify__toast-icon {
        width: 24px;
        margin-inline-end: 8px;
    }
`;

export default App;
