import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import "react-datepicker/dist/react-datepicker.css";
import "./styles/_override.scss";

if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker
            .register("/service-worker.js")
            .then((registration) => {
                console.log("SW registered: ", registration);
            })
            .catch((registrationError) => {
                console.log("SW registration failed: ", registrationError);
            });
    });
}

const container = document.getElementById("root");
createRoot(container).render(<App />);
