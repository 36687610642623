import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useSelector } from "react-redux";
import retrieveManager from "../apis/retrieveManager";
import moment from "moment";

const CountTab = () => {
    const [totalCount, setTotalCount] = useState(0);
    const [retrieveCount, setRetrieveCount] = useState(0);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        getRequestRetrieveItems();
    }, []);

    const getRequestRetrieveItems = async () => {
        const payload = {
            status: "",
            user_id: user.id,
        };
        const total = await retrieveManager.getList(payload);
        const result = total.filter((item) => item.status === "request");
        setTotalCount(total.length); // 회수 요청/완료/실패 제품 수
        setRetrieveCount(result.length); // 회수 요청 제품 수
    };

    return (
        <CountTabContainer>
            <CountTabLabel>회수</CountTabLabel>
            <CountWrap>
                <span className="count">{retrieveCount}</span>
                <span className="subfix">
                    {" "}
                    <span>/</span>
                    {totalCount}개
                </span>
            </CountWrap>
        </CountTabContainer>
    );
};

const CountWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    span.count {
        color: var(--Secondary_Blue-600_-0087D2, #0087d2);
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        display: inline-block;
        margin-right: 4px;
        letter-spacing: -0.01em;
    }
    span.subfix {
        color: var(--Gray-Scale-800-_636E73, #656e6b);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        span {
            color: var(--gray-2-abb-4-b-9, #abb4b9);
            font-weight: 600;
            display: inline-block;
            margin-right: 2px;
        }
    }
`;

const CountTabContainer = styled.div`
    display: flex;
    align-items: center;
`;

const CountTabLabel = styled.div`
    background: var(--Secondary_Blue-500_-009AE5, #009ae5);
    padding: 4px 6px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: #fff;
    margin-right: 6px;
`;

export default CountTab;
