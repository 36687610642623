import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Map, MapMarker, MarkerClusterer } from "react-kakao-maps-sdk";
import { Loading } from "../components/common";
import { useNavigate } from "react-router-dom";
import eventBus from "../components/common/EventBus";

import retrieveManager from "../apis/retrieveManager";
import styled from "styled-components";

import RetrieveListItem from "../components/RetrieveListItem";
import TopSearchBar from "../components/layouts/TopSearchBar";
import IcMarker from "../assets/images/icons/ic-marker-retrieve@2x.png";
import IcMarkerActive from "../assets/images/icons/ic-marker-retrieve-active@2x.png";
import IcMarkerSelf from "../assets/images/icons/ic-marker-self.svg";

import IcZoomControlPlus from "../assets/images/icons/ic-zoomcontrol-plus.svg";
import IcZoomControlMinus from "../assets/images/icons/ic-zoomcontrol-minus.svg";

import IcLocation_active from "../assets/images/icons/ic_location_active.svg";
import IcLocation from "../assets/images/icons/ic_location.svg";
import BottomTabNav from "../components/layouts/BottomTabNav";
import { listItems } from "../components/common/ListItems";
import { useDispatch, useSelector } from "react-redux";

const DEFAULT_LAT = 37.4826296;
const DEFAULT_LNG = 126.9921327;

const RetrieveMap = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mapRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [map, setMap] = useState();
    const [retrieveItems, setRetrieveItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isWatcher, setIsWatcher] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isUserActive, setIsUserActive] = useState(false);
    const [zoomCheck, setZoomCheck] = useState(false);
    const [clickCluster, setClickCluster] = useState(false);
    const { user, sessionData } = useSelector((state) => state.auth);
    const [position, setPosition] = useState({
        lat: DEFAULT_LAT,
        lng: DEFAULT_LNG,
    });
    const [center, setCenter] = useState({
        lat: DEFAULT_LAT,
        lng: DEFAULT_LNG,
    });

    useEffect(() => {
        let bounds = map?.getBounds(); // 지도 보이는 영역 위, 경도 계산
        if (bounds) {
            dragMap(bounds).then((sheetData) => {
                if (sheetData) {
                    // bottom sheet 데이터
                    publisherComponent(sheetData);
                }
            });
        }
    }, [center, zoomCheck, retrieveItems, clickCluster]);

    useLayoutEffect(() => {
        if (isWatcher) {
            // 실시간 내 위치(GPS) 활성화
            const watcher = navigator.geolocation.watchPosition(
                (location) => {
                    setCenter({
                        lat: location.coords.latitude,
                        lng: location.coords.longitude,
                    });
                    setPosition({
                        lat: location.coords.latitude,
                        lng: location.coords.longitude,
                    });
                    getMapList({ user_id: user.id });
                },
                (error) => {
                    console.error(error);
                },
                { enableHighAccuracy: true }
            );

            return () => {
                navigator.geolocation.clearWatch(watcher);
            };
        } else {
            // 실시간 내 위치(GPS) 바활성화 (Default)
            navigator.geolocation.getCurrentPosition((location) => {
                setPosition({
                    lat: location.coords.latitude,
                    lng: location.coords.longitude,
                });
                getMapList({ user_id: user.id });
            });
        }
    }, [isWatcher]);

    const getMapList = async (params) => {
        const sessionCheck = await retrieveManager.sessionCheck(
            user,
            sessionData
        );

        if (!sessionCheck) {
            setIsLoading(false);
            return retrieveManager.confirmOpen(navigate, dispatch);
        }

        const result = await retrieveManager.getMapList(params);
        setRetrieveItems(result);
        setIsInitialLoad(false);

        // 최초 지도 로드 시 회수 요청 제품이 가장 많은 지역으로 설정
        if (isInitialLoad) {
            let target = { item: {}, len: 0 };

            result.map((item) => {
                let bounds = {
                    ha: item.lng - 0.055,
                    qa: item.lat - 0.04,
                    oa: item.lng + 0.055,
                    pa: item.lat + 0.04,
                };

                let cnt = 0;

                result.forEach((e) => {
                    if (
                        e.lng >= bounds.ha &&
                        e.lng <= bounds.oa &&
                        e.lat >= bounds.qa &&
                        e.lat <= bounds.pa
                    ) {
                        cnt++;
                    }
                });

                if (target.len <= cnt) {
                    target.item = item;
                    target.len = cnt;
                }
            });

            if (result.length > 0) {
                setCenter({
                    lat: target.item.lat,
                    lng: target.item.lng,
                });
            } else {
                setIsWatcher(true);
            }
        }
        setIsLoading(false);
    };

    const getTexts = (size) => {
        // 하나의 클러스터 객체가 포함하는 마커의 개수에 따룬 텍스트 값 표시
        if (size > 50) {
            return "50+"; // 50개 이상일 때 텍스트 값
        } else {
            return size;
        }
    };

    const clickMarkerItem = (item) => {
        if (isUserActive) {
            setIsUserActive(false);
        }
        setSelectedItem(item);
    };

    // 내 위치 아이콘 클릭 시 GPS 기능 활성화
    const clickSelf = () => {
        setIsWatcher(true);
    };

    const moveToDetail = (item) => {
        navigate(`/retrieve-detail/${item.id}`);
    };

    const zoomIn = () => {
        if (!map) return;
        map.setLevel(map.getLevel() - 1, { animate: true });
        setZoomCheck(!zoomCheck);
    };

    const zoomOut = () => {
        if (!map) return;
        map.setLevel(map.getLevel() + 1, { animate: true });
        setZoomCheck(!zoomCheck);
    };

    // 마우스 휠로 줌 레벨 변경 시 지도에 보이는 영역 데이터 변경
    const handleWheel = () => {
        setZoomCheck(!zoomCheck);
    };

    // position 이동 시 지도 영역 마커 개수 파악
    const dragMap = async (bounds) => {
        let resultItems = [];
        let idCheck = [];
        let cnt = 0;

        await retrieveItems.forEach((item) => {
            // center 값 기준 보이는 영역의 데이터 확인
            if (bounds.contain(new kakao.maps.LatLng(item.lat, item.lng))) {
                const filterItems = retrieveItems.filter((dupItem) => {
                    if (
                        item.user_name === dupItem.user_name &&
                        item.lat === dupItem.lat &&
                        item.lng === dupItem.lng
                    ) {
                        return dupItem;
                    }
                });

                cnt += 1;

                if (!idCheck.includes(filterItems[0].id)) {
                    idCheck.push(filterItems[0].id);
                    listItems(filterItems, resultItems);
                }
            }
        });
        return [resultItems, cnt];
    };

    // 클러스터 클릭 시 보이는 영역의 데이터 값 조정
    const onClusterclick = (_target, cluster) => {
        setClickCluster(!clickCluster);
    };

    // EventBus 생성
    const publisherComponent = (item) => {
        eventBus.emit("sheetData", item);
    };

    return (
        <>
            <TopSearchBarContainer>
                <TopSearchBar />
            </TopSearchBarContainer>
            <MapContainer>
                <Loading isLoading={isLoading} subText="지도 불러오는 중" />
                <Map
                    id="map"
                    ref={mapRef}
                    center={center}
                    isPanto={true}
                    level={6}
                    style={{ width: "100%", height: `100%` }}
                    onClick={() => {
                        setSelectedItem(null);
                        setIsUserActive(false);
                    }}
                    onDragEnd={(map) => {
                        setCenter({
                            lat: map.getCenter().getLat(),
                            lng: map.getCenter().getLng(),
                        });
                        setIsWatcher(false);
                    }}
                    onCreate={setMap}
                    onWheel={handleWheel}
                    onTouchEnd={handleWheel}
                >
                    <MapMarker
                        clickable={true}
                        onClick={() => clickSelf()}
                        position={position}
                        image={{
                            src: IcMarkerSelf,
                            size: {
                                width: 36,
                                height: 36,
                            },
                        }}
                    />
                    <MarkerClusterer
                        averageCenter={true} // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
                        minLevel={6}
                        texts={getTexts}
                        onClusterclick={onClusterclick}
                    >
                        {retrieveItems.map((item, index) => (
                            <MapMarker
                                key={index}
                                clickable={true}
                                onClick={() => clickMarkerItem(item)}
                                position={{
                                    lat: item.lat,
                                    lng: item.lng,
                                }}
                                image={{
                                    src: IcMarker,
                                    size: {
                                        width: 30,
                                        height: 30,
                                    },
                                }}
                            ></MapMarker>
                        ))}
                    </MarkerClusterer>
                </Map>

                <MapControl>
                    <CustomZoomControl>
                        <span onClick={zoomIn}>
                            <img src={IcZoomControlPlus} alt="확대" />
                        </span>
                        <span onClick={zoomOut}>
                            <img src={IcZoomControlMinus} alt="축소" />
                        </span>
                    </CustomZoomControl>

                    <MoveToMyPin
                        onClick={() => setIsWatcher(!isWatcher)}
                        className={!isWatcher ? "" : "active"}
                    >
                        {!isWatcher ? (
                            <img src={IcLocation} alt="location icon" />
                        ) : (
                            <img src={IcLocation_active} alt="location icon" />
                        )}
                    </MoveToMyPin>
                </MapControl>

                {selectedItem ? (
                    <AbsolutePopupItemWrap>
                        <RetrieveListItem
                            onClickFunc={() => moveToDetail(selectedItem)}
                            item={selectedItem}
                            isClickable={false}
                        />
                    </AbsolutePopupItemWrap>
                ) : null}
                <BottomTabNav />
            </MapContainer>
        </>
    );
};

const MapControl = styled.div`
    position: absolute;
    bottom: 110px;
    right: 12px;
    z-index: 50;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const CustomZoomControl = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 4px;
    border: 1px solid var(--Gray-Scale-300-_D4D6D4, #d1d3d3);
    background: var(--White, #fff);
    box-shadow: 0px 0px 12px 0px rgba(40, 53, 134, 0.08);

    span {
        display: Flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:first-child {
            border-bottom: 1px solid var(--Gray-Scale-300-_D4D6D4, #d1d3d3);
        }
    }
`;

const MoveToMyPin = styled.div`
    cursor: pointer;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    border: 1px solid var(--Gray-Scale-300-_D4D6D4, #d1d3d3);
    box-shadow: 0px 0px 12px 0px rgba(40, 53, 134, 0.08);

    &.active {
        background: var(--Primary-500_-00913A, #00913a);
    }

    span {
        display: block;
        color: var(--primary-vari-1008-f-41, #008f41);
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 11px */
    }
`;

const AbsolutePopupItemWrap = styled.div`
    position: fixed;
    width: 100%;
    bottom: env(safe-area-inset-bottom, 0);
    padding: 20px 16px;
    z-index: 50;
`;

const MapContainer = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 125px);
`;

const TopSearchBarContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export default RetrieveMap;
