import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`
  ${reset}

  //@font-face {
  //  font-family: 'Pretendard-Regular';
  //  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  //  font-weight: 400;
  //  font-style: normal;
  //}
  
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  * {
    font-family: "Pretendard Variable", Pretendard,sans-serif !important;
  }
  
  img {
    user-select: none;
  }

  html, body, #root, #contentWrap {
    height: 100%;
  }
  
  body {
    -webkit-tap-highlight-color : transparent !important;
    min-width: 320px !important;
    overflow: hidden;
  }
  
  #root {
    position: relative;
    //overflow: hidden;
  }
  
  th, td {
    vertical-align: middle;
  }
  
  button {
    cursor: pointer;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
    &:disabled {
      cursor: not-allowed;
    }
  }
  
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  
  input, textarea {
    outline: none;
    &:disabled {
      cursor: not-allowed;
    }
  }
  
  h1, p, span {
    letter-spacing: -0.01em;
  }

  .displaynone{display: none !important;}

  select::-ms-expand {display: none;}
  span{color: inherit; font-size: inherit; line-height: inherit; font-family: inherit;}
  label{cursor: pointer;}
  /* ie input icon display:none */
  input::-ms-clear, input::-ms-reveal {
    display: none;
  }
  .hidden{
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    min-width: initial !important;
    min-height: initial !important;
    overflow: hidden !important;
    opacity: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
  }
  
  html {
    --white: #fff;
    --black: #282B39;
  }
`;

export default GlobalStyle;
