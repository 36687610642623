import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Loading } from "../components/common";
import DetailHeader from "../components/common/DetailHeader";
import IcCircleWarning from "../assets/images/icons/ic_circle_warning.svg";
import { closeConfirm, openConfirm } from "../redux/ConfirmSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import retrieveManager from "../apis/retrieveManager";
import { listItems } from "../components/common/ListItems";

const Beacon = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState("basic");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, sessionData } = useSelector((state) => state.auth);

    useEffect(() => {
        accessCheck();
    }, []);

    const accessCheck = async () => {
        const sessionCheck = await retrieveManager.sessionCheck(
            user,
            sessionData
        );

        if (!sessionCheck) {
            setIsLoading(false);
            return retrieveManager.confirmOpen(navigate, dispatch);
        }

        if (
            /iPhone|iPad/i.test(navigator.userAgent) ||
            /Android/i.test(navigator.userAgent)
        ) {
            const data = {
                action: "ble_beacon",
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(data));

            bleSearchHandler();
        } else {
            setIsLoading(false);
            dispatch(
                openConfirm({
                    confirmText: "확인",
                    isSingleButton: true,
                    notCloseOnClickDimmed: true,
                    title: "모바일 전용 기능입니다.",
                    confirmFunc: () => {
                        dispatch(closeConfirm());
                        navigate(-1);
                    },
                })
            );
        }
    };

    const bleSearchHandler = () => {
        async function beaconHandleEvent(message) {
            // 블루투스 정보 수신
            const beaconMessage = JSON.parse(message.data);
            console.log(beaconMessage);

            if (beaconMessage.action === "ble_error") {
                //10초 동안 블루투스 미인식인 경우 인식 실패 처리
                setIsLoading(false);
                setPage("fail");
            } else if (beaconMessage.action === "ble_beacon") {
                let resultItems = [];

                const result = await retrieveManager.getBleList(
                    beaconMessage.data
                );

                if (result.data === "null") {
                    // 비콘이 삭제 되었을 경우 오류 페이지 띄우기
                    setIsLoading(false);
                    setPage("not_request");
                    return;
                }

                const filterItems = result.data.filter((dupItem) => {
                    if (
                        result.retrieve.user_name === dupItem.user_name &&
                        result.retrieve.lat === dupItem.lat &&
                        result.retrieve.lng === dupItem.lng &&
                        dupItem.status === "request"
                    ) {
                        return dupItem;
                    }
                });

                if (filterItems.length === 0) {
                    // 회수품이 회수 완료 상태일 경우 오류 페이지 띄우기
                    setIsLoading(false);
                    setPage("not_request");
                    return;
                }

                await listItems(filterItems, resultItems);
                await setIsLoading(false);

                navigate(`/retrieve-detail/${resultItems[0].id}`, {
                    state: { group: resultItems[0], action: "beacon" },
                });
            }
            window.removeEventListener("message", beaconHandleEvent, true);
        }

        window.addEventListener("message", beaconHandleEvent, true);
    };

    return (
        <>
            <Loading
                isLoading={isLoading}
                subText={"반납 제품을 인식하고 있습니다."}
            />
            <BeaconContainer>
                <DetailHeader title={"비콘 인식"} />
                {page && page === "basic" ? <div></div> : null}
                {page && page === "fail" ? (
                    // 인식 실패 혹은 회수 요청 미접수 제품 화면입니다.
                    <BeaconContainerFail className="scan-fail">
                        <img src={IcCircleWarning} />
                        <h2>제품 인식에 실패했습니다.</h2>
                        <p>블루투스 설정을 확인해 주세요.</p>
                    </BeaconContainerFail>
                ) : null}
                {page && page === "not_request" ? (
                    <BeaconContainerFail className="mismatch">
                        <img src={IcCircleWarning} />
                        <h2>회수 요청이 접수되지 않은 제품입니다.</h2>
                    </BeaconContainerFail>
                ) : null}
            </BeaconContainer>
        </>
    );
};

const BeaconContainer = styled.div`
    width: 100%;
    height: calc(100dvh - 124px);

    h1 {
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }

    & > section {
        height: 100%;
        & > div {
            padding-top: unset;
            height: 100%;
        }
    }
`;

const BeaconContainerFail = styled.div`
    height: calc(100dvh - 124px);
    padding: 0 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h2 {
        margin-top: 16px;
        color: var(--Black, #222);
        font-size: 18px;
        font-weight: 600;
        line-height: 145%; /* 26.1px */
    }

    p {
        margin-top: 8px;
        color: var(--Gray-Scale-800-_636E73, #656e6b);
        font-size: 14px;
        font-weight: 500;
        line-height: 160%;
    }
`;

export default Beacon;
