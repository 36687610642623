import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import IcLoading from "../../assets/images/icons/ic_loading.svg";

const Loading = ({ isLoading, subText }) => {
    if (!isLoading) return;
    return (
        <LoadingContainer subText={subText}>
            <img src={IcLoading} alt="loading" className="lds-ring" />
            {subText && <p>{subText}</p>}
        </LoadingContainer>
    );
};

const LoadingContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: ${({ subText }) =>
        subText === "반납 제품을 인식하고 있습니다." ? 1002 : 100};
    top: 0;
    left: 0;

    .lds-ring {
        animation: lds-ring 1s linear infinite;
    }

    p {
        margin-top: 16px;
        color: #fff;
        text-align: center;
        font-size: 17px;
        font-weight: 600;
        line-height: 150%; /* 25.5px */
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export default Loading;
