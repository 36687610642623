import styled from "styled-components";
import PropTypes from "prop-types";

import IcArrow from "../assets/images/icons/ic-arrow.svg";
import dayjs from "dayjs";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { closeConfirm, openConfirm } from "../redux/ConfirmSlice";
import { useDispatch } from "react-redux";

const RetrieveListItem = ({
    className,
    isClickable,
    onClickFunc,
    item,
    type,
}) => {
    const [searchParams] = useSearchParams();
    const status = searchParams.get("status");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const moveToTmap = (item, e) => {
        e.preventDefault();

        if (
            /iPhone|iPad/i.test(navigator.userAgent) ||
            /Android/i.test(navigator.userAgent)
        ) {
            // 티맵 실행 시 필요한 주소와 위, 경도 메시지 전송 (Webview => Native)
            const data = {
                action: "open_tmap",
                address: item.user_address,
                lat: item.lat,
                lng: item.lng,
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        } else {
            dispatch(
                openConfirm({
                    confirmText: "확인",
                    isSingleButton: true,
                    title: "모바일 전용 기능입니다.",
                    confirmFunc: () => {
                        dispatch(closeConfirm());
                    },
                })
            );
        }
    };

    return (
        <ListItemContainer className={className}>
            <RetrieveInfoWrap>
                <FlexItemContainer
                    $type={type}
                    onClick={() => onClickFunc(item)}
                >
                    {isClickable ? (
                        <>
                            {item.count > 1 ? (
                                <div
                                    className={
                                        status === "request"
                                            ? "list-request-group"
                                            : "list-complete-group"
                                    }
                                >
                                    <span className="list-item-text">
                                        {item.count}
                                    </span>
                                </div>
                            ) : null}
                            <img
                                className="list-item-arrow"
                                src={IcArrow}
                                alt={"icon"}
                            />
                        </>
                    ) : null}
                    <FlexItemTopWrap>
                        <span className="date">
                            {dayjs(
                                status === "request"
                                    ? item.createdAt
                                    : item.completed_at
                            ).format("YYYY-MM-DD")}
                        </span>
                        <div
                            className={
                                status === "request"
                                    ? "status-request-area"
                                    : "status-complete-area"
                            }
                        >
                            <span className="status_text">회수완료</span>
                        </div>
                    </FlexItemTopWrap>
                    <FlexItemWrap>
                        <span className="key">수취인</span>
                        <span className="val">{item.user_name}</span>
                    </FlexItemWrap>
                    {type === "list" ? (
                        <>
                            <FlexItemWrap>
                                <span className="key">주소</span>
                                <span className="val">{item.user_address}</span>
                            </FlexItemWrap>
                            <FlexItemWrap>
                                <span className="key">상세주소</span>
                                <span className="val">
                                    {item.user_detail_address || "-"}
                                </span>
                            </FlexItemWrap>
                            <FlexItemWrap>
                                <span className="key">출입정보</span>
                                <span className="val">
                                    {item.entry_way === "no_common_front_door"
                                        ? "공동현관 없음"
                                        : item.entry_way_detail}
                                </span>
                            </FlexItemWrap>
                        </>
                    ) : null}
                </FlexItemContainer>
                <TmapBtnWrap status={status}>
                    <TmapButton
                        className={"large"}
                        onClick={(e) => moveToTmap(item, e)}
                    >
                        T맵으로 연결
                    </TmapButton>
                </TmapBtnWrap>
            </RetrieveInfoWrap>
        </ListItemContainer>
    );
};

const FlexItemTopWrap = styled.div`
    display: flex;
    margin-bottom: 16px;
    span.date {
        color: #656e6b;
        font-size: 17px;
        font-weight: 600;
        line-height: 150%; /* 16.5px */
        letter-spacing: -0.11px;
    }

    div.status-request-area {
        display: none;
    }

    div.status-complete-area {
        margin: 0 0 0 10px;
        padding: 4px 6px 4px 6px;
        border-radius: 3px;
        background-color: #afb4b2;
    }

    span.status_text {
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: #ffffff;
    }
`;

const FlexItemWrap = styled.div`
    display: flex;
    align-items: baseline;
    gap: 8px;
    &:first-of-type {
        margin-top: 0;
    }
    span.key {
        flex-basis: 60px;
        margin-top: 8px;
        color: var(--gray-1636-e-73, #656e6b);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }
    span.val {
        flex: 1;
        color: #222222;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
    }
`;

const FlexItemContainer = styled.div`
    cursor: pointer;

    div {
        margin-top: ${(props) => (props.$type === "list" ? 8 : 4)}px;
    }
`;

const RetrieveInfoWrap = styled.div`
    flex: 1;
    span {
        display: block;
    }
`;

const ListItemContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 10px 20px;
    border-bottom: 1px solid #edeeed;
    border-radius: 10px;
    background: #ffffff;
    margin-bottom: 10px;
    &:last-of-type {
        margin-bottom: 0;
    }

    div.list-request-group {
        width: 28px;
        height: 28px;
        position: absolute;
        margin-top: 0;
        top: 35px;
        transform: translate(0, -50%);
        right: 35px;
        border-radius: 999px;
        padding: 7px 4px 7px 4px;
        background-color: #009ae5;
    }

    div.list-complete-group {
        width: 28px;
        height: 28px;
        position: absolute;
        margin-top: 0;
        top: 35px;
        transform: translate(0, -50%);
        right: 35px;
        border-radius: 999px;
        padding: 7px 4px 7px 4px;
        background-color: #d1d3d3;
    }

    img.list-item-arrow {
        user-select: none;
        position: absolute;
        top: 35px;
        transform: translate(0, -50%);
        right: 4px;
    }

    span.list-item-text {
        text-align: center;
        font-family: Pretendard, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        color: #ffffff;
    }
`;

const TmapBtnWrap = styled.div`
    display: ${({ status }) => (status === "request" ? "flex" : "none")};
    margin: 25px 0 15px 0;
`;

const TmapButton = styled.button`
    width: 100%;
    height: 45px;
    background-color: #005779;

    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    border-radius: 100px;
    text-align: center;

    &.large {
        line-height: 1;
        padding: 18px 0;
        height: initial;
        &:disabled {
            background: var(--Gray-Scale-300-_D4D6D4, #d1d3d3);
        }
        &.type2 {
            border: 1px solid var(--Primary-600_-007D31, #007d31);
            background: var(--White, #fff);
            color: var(--Primary-600_-007D31, #007d31);
            &:disabled {
                border-color: var(--Gray-Scale-300-_D4D6D4, #d1d3d3);
                color: var(--Gray-Scale-300-_D4D6D4, #d1d3d3);
            }
        }
    }
`;

RetrieveListItem.propTypes = {
    isClickable: PropTypes.bool,
    type: PropTypes.oneOf(["map", "list"]),
};

RetrieveListItem.defaultProps = {
    isClickable: true,
    type: "map",
};

export default RetrieveListItem;
