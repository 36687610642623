import React, { useEffect } from "react";
import styled from "styled-components";

import { useSelector, useDispatch } from "react-redux";
import { closeConfirm } from "../../redux/ConfirmSlice";

const Confirm = ({ className }) => {
    const dispatch = useDispatch();
    const {
        isOpen,
        text,
        title,
        cancelFunc,
        confirmFunc,
        cancelText,
        confirmText,
        closeFunc,
        isSingleButton,
        closeDisabled,
        notCloseOnClickDimmed,
        width,
    } = useSelector((state) => state.confirm);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && isOpen) {
                handleCloseConfirm();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, dispatch]);

    const handleCloseConfirm = () => {
        if (closeDisabled) return;
        if (closeFunc) {
            closeFunc();
        }
        dispatch(closeConfirm());
    };

    const clickDimmer = (e) => {
        if (!notCloseOnClickDimmed) {
            handleCloseConfirm();
        }
    };

    const clickContainer = (e) => {
        e.stopPropagation();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Dimmer className={className} onClick={clickDimmer}>
            <ConfirmContainer
                className={"modal-container"}
                onClick={clickContainer}
                $width={width}
            >
                <ConfirmBody>
                    <ConfirmBodyContent>
                        {title ? <h1>{title}</h1> : null}
                        {text ? <p>{text}</p> : null}
                    </ConfirmBodyContent>
                    <ConfirmButtonWrap $width={width}>
                        <BtnWrap>
                            {!isSingleButton ? (
                                <ConfirmButton
                                    type={"outline"}
                                    className={"close-btn"}
                                    onClick={cancelFunc || handleCloseConfirm}
                                >
                                    {cancelText}
                                </ConfirmButton>
                            ) : null}
                            <ConfirmButton
                                type={"fill"}
                                className={"confirm-btn"}
                                onClick={confirmFunc}
                            >
                                {confirmText}
                            </ConfirmButton>
                        </BtnWrap>
                    </ConfirmButtonWrap>
                </ConfirmBody>
            </ConfirmContainer>
        </Dimmer>
    );
};

const ConfirmButtonWrap = styled.div`
    border-top: 1px solid #edeeed;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ConfirmButton = styled.button`
    height: 52px;
    text-align: center;
    width: 50%;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    &.close-btn {
        color: var(--main-black-222222, #222);
        border-right: 1px solid #edeeed;
        margin-left: -1px;
    }
    &.confirm-btn {
        color: #008f41;
    }
`;

const Dimmer = styled.div`
    position: fixed;
    z-index: 1110;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ConfirmContainer = styled.article`
    box-sizing: content-box;
    min-width: 320px;
    max-width: ${(props) => (props.$width ? props.$width : "400")}px;
    width: 100%;
    box-shadow: 0 0 10px rgba(98, 120, 233, 0.1);
    background-color: #ffffff;
    border: 1px solid #cbcbcb;
    border-radius: 16px;
`;

const ConfirmBody = styled.div`
    width: 100%;
    max-height: 500px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--gray-400);
        border-radius: 100px;
        border-right: 4px solid #fff;
        border-left: 4px solid #fff;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
    }
    &::-webkit-scrollbar-track {
        width: 12px;
        background-color: transparent;
    }
`;

const ConfirmBodyContent = styled.div`
    padding: 32px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        color: var(--main-black-222222, #222);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
    }

    p {
        margin-top: 12px;
        color: var(--gray-1636-e-73, #636e73);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
    }
`;

const BtnWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default Confirm;
