import React, { useState } from "react";
import styled from "styled-components";

import IcDownArrow from "../assets/images/icons/ic-chevron-right.svg";

const MyList = ({ className, item, date }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <MyListContainer className={className}>
            <MyListHeader>
                <span className="date">{date.slice(0, 10)}</span>
                <HeaderLabel status={date.slice(10)}>
                    {date.slice(10) === "complete" ? (
                        <>
                            <CompleteLabel>회수</CompleteLabel>
                            <CompleteCount>{item.length}</CompleteCount>
                        </>
                    ) : (
                        <>
                            <FailedLabel>실패</FailedLabel>
                            <FailedCount>{item.length}</FailedCount>
                        </>
                    )}
                </HeaderLabel>
            </MyListHeader>
            <ContentWrap className={expanded ? "expanded" : ""}>
                <ul>
                    {item.map((myItem, index) => (
                        <li key={index}>
                            <div className="date-wrap">
                                <span className="date">
                                    {myItem.completed_at
                                        ? myItem.completed_at.split(" ")[0]
                                        : "-"}
                                </span>
                                <span className="time">
                                    {myItem.completed_at
                                        ? myItem.completed_at?.split(" ")[1]
                                        : "-"}
                                </span>
                            </div>
                            <span className="uid">
                                {myItem.Asset
                                    ? myItem.Asset.unique_id
                                    : "알 수 없음"}
                            </span>
                            {/* <div className="block"></div> */}
                        </li>
                    ))}
                </ul>
            </ContentWrap>
            <ExpandBtnWrap
                onClick={() => {
                    setExpanded(!expanded);
                }}
            >
                <span>{expanded ? "접기" : "더보기"}</span>
                <img
                    src={IcDownArrow}
                    alt="arrow icon"
                    className={expanded ? "expanded" : ""}
                />
            </ExpandBtnWrap>
        </MyListContainer>
    );
};

const ContentWrap = styled.div`
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;

    &.expanded {
        max-height: 1000px; /* Adjust this value based on your content */
    }

    ul {
        max-height: 50vh;
        overflow-y: auto;
        li {
            padding: 20px 24px;
            border-bottom: 1px solid var(--gray-7-edeeed, #edeeed);
            display: flex;
            flex-direction: column;
            gap: 2px;

            div.date-wrap {
                display: flex;

                span {
                    color: var(--Gray-Scale-800-_636E73, #656e6b);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: -0.01em;

                    & + span {
                        margin-left: 3px;
                    }
                }
            }
            span.uid {
                color: var(--Black, #222);
                font-size: 17px;
                font-weight: 600;
                line-height: 150%; /* 25.5px */
            }
        }
    }
`;

const ExpandBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 16px 0;
    cursor: pointer;

    img {
        display: block;
        transition: transform 0.2s ease-in-out;
        transform: rotate(90deg);
        width: 16px;
        height: 16px;

        &.expanded {
            transform: rotate(-90deg);
        }
    }

    span {
        color: var(--Gray-Scale-700-_848B88, #848b88);
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 15px */
    }
`;

const HeaderLabel = styled.div`
    display: flex;
    align-items: center;
`;

const CompleteLabel = styled.span`
    display: block;
    border-radius: 999px;
    background: var(--Secondary_Blue-500_-009AE5, #009ae5);
    color: var(--white-ffffff, #fff);
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
    padding: 4px 6px;
    margin-right: 8px;
`;

const CompleteCount = styled.div`
    color: var(--Secondary_Blue-500_-009AE5, #009ae5);
    font-size: 24px;
    font-weight: 700;
    line-height: 100%; /* 24px */
`;

const FailedLabel = styled.span`
    display: block;
    border-radius: 999px;
    background: #ff0000;
    color: var(--white-ffffff, #fff);
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
    padding: 4px 6px;
    margin-right: 8px;
`;

const FailedCount = styled.div`
    color: #ff0000;
    font-size: 24px;
    font-weight: 700;
    line-height: 100%; /* 24px */
`;

const MyListHeader = styled.div`
    position: relative;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--Gray-Scale-100-_EDEEED, #edeeed);

    span.date {
        color: var(--Gray-Scale-900_-36423E, #36423e);
        font-size: 18px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -0.01em;
    }
`;

const MyListContainer = styled.div`
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 0px 12px 0px rgba(40, 53, 134, 0.08);
`;

export default MyList;
