import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { Navigate } from "react-router-dom";
import { Button, Input } from "../components/common";

import IcCheck from "../assets/images/icons/ic-check@2x.png";
import IcCheckActive from "../assets/images/icons/ic-check-active@2x.png";

import SyntroLogo from "../assets/images/logo@2x.png";
import accountManager from "../apis/accountManager";
import { useDispatch, useSelector } from "react-redux";
import {
    sessionSave,
    loginSuccess,
    setUserInputPassword,
    toggleRememberPassword,
} from "../redux/AuthSlice";
import { useNavigate } from "react-router-dom";
import { openConfirm, closeConfirm } from "../redux/ConfirmSlice";

const Login = () => {
    const { user, sessionData, userInputPassword, rememberPassword } =
        useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        // 비밀번호 기억하기
        if (rememberPassword) setPassword(userInputPassword);

        // 사용자, 세선 정보 및 만료 기한 확인 후 자동 로그인
        if (
            user &&
            sessionData &&
            user.id === sessionData.passport.user &&
            sessionData.cookie.expires >= moment().format("YYYY-MM-DD HH:mm:ss")
        ) {
            navigate("/");
        }
    }, []);

    const submitLogin = async (e) => {
        e.preventDefault();

        try {
            const result = await accountManager.login({ id, password });
            if (result) {
                dispatch(loginSuccess(result.user));
                dispatch(sessionSave(result.session));
                navigate("/");
            }
        } catch (e) {
            dispatch(
                openConfirm({
                    confirmText: "확인",
                    isSingleButton: true,
                    title: "로그인 실패",
                    text: `${
                        e.response?.data?.description || "알 수 없는 에러"
                    }`,
                    confirmFunc: () => {
                        dispatch(closeConfirm());
                    },
                })
            );
        }
    };

    const handleIdChange = (e) => {
        setId(e.target.value);
    };

    const handlePasswordChange = (e) => {
        const inputPassword = e.target.value;
        setPassword(inputPassword);
        if (rememberPassword) {
            dispatch(setUserInputPassword(inputPassword));
        } else {
            dispatch(setUserInputPassword(""));
        }
    };

    const togglePassword = (e) => {
        const isChecked = e.target.checked;
        dispatch(toggleRememberPassword(isChecked));
        if (isChecked) {
            dispatch(setUserInputPassword(password));
        } else {
            dispatch(setUserInputPassword(""));
        }
    };

    return (
        <PageContainer>
            <img id="syntroLogo" src={SyntroLogo} alt="" />

            <form onSubmit={submitLogin}>
                <Input
                    imageType={"user"}
                    value={id}
                    onChange={handleIdChange}
                />
                <Input
                    imageType={"password"}
                    value={password}
                    onChange={handlePasswordChange}
                    type="password"
                />
                <CustomLoginCheckboxWrap>
                    <input
                        type="checkbox"
                        id="rememberId"
                        onChange={togglePassword}
                        defaultChecked={rememberPassword}
                    />
                    <label htmlFor="rememberId" id="imageLabel"></label>
                    <label htmlFor="rememberId">비밀번호 기억하기</label>
                </CustomLoginCheckboxWrap>
                <CustomButton type="submit">로그인</CustomButton>
            </form>
        </PageContainer>
    );
};

const CustomButton = styled(Button)`
    font-size: 17px;
    height: 56px;
`;

const CustomLoginCheckboxWrap = styled.div`
    position: relative;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: end;

    input[type="checkbox"] {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: 1px;
        height: 1px;

        & + label {
            background-image: url(${IcCheck});
            width: 24px;
            height: 24px;
            background-position: center;
            background-size: 24px;
            background-repeat: no-repeat;
        }

        &:checked + label {
            background-image: url(${IcCheckActive});
        }
    }
`;

const PageContainer = styled.article`
    background-color: #f6faed;
    height: 100vh;
    border-radius: 4px;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img#syntroLogo {
        width: 232px;
        pointer-events: none;
    }

    form {
        width: 100%;
        margin-top: 50px;

        div {
            margin-bottom: 10px;
        }
    }
`;

export default Login;
