import { instance } from "./index";
import moment from "moment/moment";
import { closeConfirm, openConfirm } from "../redux/ConfirmSlice";

const sessionCheck = (user, sessionData) => {
    // 로그인 유지를 위한 세션 및 유저 정보 확인 (메모리 세션 스토어 사용으로 앱 종료 시 상태 유지 정보 없음)
    return !(
        !user ||
        !sessionData ||
        sessionData.cookie.expires < moment().format("YYYY-MM-DD HH:mm:ss")
    );
};

const confirmOpen = (navigate, dispatch) => {
    dispatch(
        openConfirm({
            confirmText: "확인",
            isSingleButton: true,
            notCloseOnClickDimmed: true,
            title: "로그인 세션이 만료되었습니다.",
            confirmFunc: () => {
                dispatch(closeConfirm());
                navigate("/login");
            },
        })
    );
};

const getMapList = ({ user_id }) => {
    const params = {
        user_id: user_id,
    };

    return instance.get(`/retrieveApp/retrieveMapList`, {
        params,
    });
};

const getList = ({
    user_id,
    lat,
    lng,
    status,
    s_date,
    e_date,
    category,
    text,
}) => {
    const params = {};
    if (lat !== undefined) params.lat = lat;
    if (lng !== undefined) params.lng = lng;
    if (user_id !== undefined) params.user_id = user_id;
    if (status !== undefined) params.status = status;
    if (s_date !== undefined) params.s_date = s_date;
    if (e_date !== undefined) params.e_date = e_date;
    if (category !== undefined) params.category = category;
    if (text !== undefined) params.text = text;
    if (category === "기간") {
        params.category = "";
        params.text = "";
    }

    return instance.get(`/retrieveApp/retrieveList`, {
        params,
    });
};

const getById = (id) => {
    return instance.get(`/retrieveApp/retrieve/${id}`);
};

const getMyList = (user_id, s_date, e_date) => {
    const params = {};
    if (user_id !== undefined) params.user_id = user_id;
    if (s_date !== undefined) params.s_date = s_date;
    if (e_date !== undefined) params.e_date = e_date;
    return instance.get(`/retrieveApp/myRetrieveList`, {
        params: params,
    });
};

const getBleList = (beacon_id) => {
    const params = {};
    if (beacon_id !== undefined) params.beacon_id = beacon_id;
    return instance.get(`/retrieveApp/bleBeacon`, {
        params: params,
    });
};

const completeRetrieve = (id) => {
    return instance.put(`/retrieveApp/retrieve/${id}`);
};

const checkQRScanTag = (unique_id) => {
    return instance.get(`/retrieveApp/asset/retrieve/${unique_id}`);
};

const vocRegister = (formData) => {
    return instance.post(`/retrieveApp/retrieveVoc`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

const retrieveManager = {
    sessionCheck,
    confirmOpen,
    getList,
    getById,
    completeRetrieve,
    getMapList,
    getMyList,
    checkQRScanTag,
    vocRegister,
    getBleList,
};

export default retrieveManager;
