import { instance } from "./index";

const login = ({ id, password }) => {
    return instance.post(`/retrieveApp/doLogin`, {
        login_id: id,
        password,
    });
};

const logout = () => {
    return instance.post(`/retrieveApp/doLogout`);
};

const accountManager = {
    login,
    logout,
};

export default accountManager;
