import BellIcon from "../assets/images/icons/ic-bell.svg";

export const DELIVERY_STATUS = {
    DELIVERY: "delivery",
    REQUEST: "request",
    COMPLETE: "complete",
    DUNNING: "dunning",
    FAIL: "fail",
};

export const DELIVERY_STATUS_TEXT = {
    delivery: "배송중",
    request: "회수요청",
    complete: "회수완료",
    dunning: "회수독촉",
    fail: "회수실패",
};

export const TOAST_PROPS = {
    icon: () => <img src={BellIcon} alt="bell icon" />,
    position: "bottom-center",
    autoClose: 1500,
    hideProgressBar: true,
    // closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};
