import React, { useState } from "react";
import styled from "styled-components";

import IcArrowDown from "../../assets/images/icons/ic-arrow-selectbox.svg";

const SelectBox = ({ name, options, defaultValue, handleSelectChange }) => {
    const [selected, setSelected] = useState(defaultValue);
    const handleSelect = (e) => {
        setSelected(e.target.value);
        handleSelectChange(e.target.value);
    };

    return (
        <StyledSelectBox
            name={name}
            arrowImage={IcArrowDown}
            value={selected}
            onChange={handleSelect}
        >
            <option disabled>{defaultValue}</option>
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.name}
                </option>
            ))}
        </StyledSelectBox>
    );
};

const StyledSelectBox = styled.select`
    border: 1px solid var(--Gray-Scale-300-_D4D6D4, #d1d3d3);
    border-radius: 4px;
    padding: 12px 16px;
    width: 100%;
    background: url(${(props) => props.arrowImage}) no-repeat center right 12px /
        24px;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; /* 15px */
`;

export default SelectBox;
