import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import eventBus from "../../components/common/EventBus";

import Setting from "../svg/Setting";
import List from "../svg/List";
import Complete from "../svg/Complete";
import QrCamera from "../svg/QrCamera";
import BlBeacon from "../svg/BlBeacon";
import Map from "../svg/Map";
import BottomSheet from "../BottomSheet";
import BottomSheetArrow from "../../assets/images/icons/ic_bottomSheetArrow.svg";
import {
    closeDatePicker,
    updateDateState,
} from "../../redux/DatePickerModalSlice";
import { setSearchCategory, setSearchText } from "../../redux/RetrieveSlice";
import { useDispatch } from "react-redux";
import IcArrow from "../../assets/images/icons/ic-arrow.svg";
import dayjs from "dayjs";
import { closeConfirm, openConfirm } from "../../redux/ConfirmSlice";

const ACTIVE_COLOR = "#008f41";

const BottomTabNav = () => {
    const [uri, setUri] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
    const dispatch = useDispatch();
    const [containItem, setContainItem] = useState([]);
    const [itemCount, setItemCount] = useState(0);

    const toggleBottomSheet = () => {
        setBottomSheetOpen(!isBottomSheetOpen);
    };

    useEffect(() => {
        setBottomSheetOpen(false);
        const item = location.pathname + location.search;
        setUri(item);
    }, [location]);

    // EventBus 수신
    useEffect(() => {
        const handleMyEvent = (item) => {
            setContainItem(item[0]);
            setItemCount(item[1]);
        };
        // 이벤트 수신하기
        eventBus.on("sheetData", handleMyEvent);
        return () => {
            // 컴포넌트가 사라질 때에는 이벤트 수신 종료
            eventBus.off("sheetData", handleMyEvent);
        };
    }, []);

    const clickTab = async (type) => {
        dispatch(
            updateDateState({ selectedStartDate: null, selectedEndDate: null })
        );
        dispatch(closeDatePicker());
        dispatch(setSearchCategory("이름"));
        dispatch(setSearchText(""));

        if (type === "setting") {
            navigate("/tab/setting");
        } else if (type === "request") {
            navigate("/tab?status=request");
        } else if (type === "complete") {
            navigate("/tab?status=complete");
        } else if (type === "qrCamera") {
            navigate("/tab/qr");
        } else if (type === "blBeacon") {
            navigate("/tab/beacon");
        } else if (type === "tab") {
            navigate("/");
        }
    };

    const moveToDetail = (item) => {
        // 회수품(이름, 주소 동일) 상세 조회 데이터 전송
        navigate(`/retrieve-detail/${item.id}`, {
            state: { group: item, action: "bottomSheet" },
        });
    };

    const moveToTmap = (item, e) => {
        e.preventDefault();

        if (
            /iPhone|iPad/i.test(navigator.userAgent) ||
            /Android/i.test(navigator.userAgent)
        ) {
            // 티맵 실행 시 필요한 주소와 위, 경도 메시지 전송 (Webview => Native)
            const data = {
                action: "open_tmap",
                address: item.user_address,
                lat: item.lat,
                lng: item.lng,
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        } else {
            dispatch(
                openConfirm({
                    confirmText: "확인",
                    isSingleButton: true,
                    title: "모바일 전용 기능입니다.",
                    confirmFunc: () => {
                        dispatch(closeConfirm());
                    },
                })
            );
        }
    };

    return (
        <>
            <BottomSheetWrapper uri={uri}>
                <BottomSheetContainer>
                    <BottomSheet
                        isOpen={isBottomSheetOpen}
                        onClose={toggleBottomSheet}
                    >
                        <BottomSheetContent onClick={toggleBottomSheet}>
                            <BottomSheetBtn>
                                <button>
                                    <img src={BottomSheetArrow} />
                                </button>
                            </BottomSheetBtn>
                            <BottomSheetText>
                                <h2>회수 요청</h2>
                                <p>{itemCount > 50 ? "50+" : itemCount}</p>
                            </BottomSheetText>
                        </BottomSheetContent>
                        <SheetItemContainer>
                            {containItem &&
                                containItem.map((item, index) => (
                                    <RetrieveInfoWrap key={item.id}>
                                        <FlexItemContainer
                                            onClick={() => moveToDetail(item)}
                                        >
                                            {item.count > 1 ? (
                                                <div className="list-request-group">
                                                    <span className="list-item-text">
                                                        {item.count}
                                                    </span>
                                                </div>
                                            ) : null}
                                            <img
                                                className="list-item-arrow"
                                                src={IcArrow}
                                                alt={"icon"}
                                            />
                                            <FlexItemTopWrap>
                                                <span className="date">
                                                    {dayjs(
                                                        item.createdAt
                                                    ).format("YYYY-MM-DD")}
                                                </span>
                                            </FlexItemTopWrap>
                                            <FlexItemWrap>
                                                <span className="key">
                                                    수취인
                                                </span>
                                                <span className="val">
                                                    {item.user_name}
                                                </span>
                                            </FlexItemWrap>
                                            <FlexItemWrap>
                                                <span className="key">
                                                    주소
                                                </span>
                                                <span className="val">
                                                    {item.user_address}
                                                </span>
                                            </FlexItemWrap>
                                            <FlexItemWrap>
                                                <span className="key">
                                                    상세주소
                                                </span>
                                                <span className="val">
                                                    {item.user_detail_address ||
                                                        "-"}
                                                </span>
                                            </FlexItemWrap>
                                            <FlexItemWrap>
                                                <span className="key">
                                                    출입정보
                                                </span>
                                                <span className="val">
                                                    {item.entry_way ===
                                                    "no_common_front_door"
                                                        ? "공동현관 없음"
                                                        : item.entry_way_detail}
                                                </span>
                                            </FlexItemWrap>
                                        </FlexItemContainer>
                                        <TmapBtnWrap>
                                            <TmapButton
                                                className={"large"}
                                                onClick={(e) =>
                                                    moveToTmap(item, e)
                                                }
                                            >
                                                T맵으로 연결
                                            </TmapButton>
                                        </TmapBtnWrap>
                                    </RetrieveInfoWrap>
                                ))}
                        </SheetItemContainer>
                    </BottomSheet>
                </BottomSheetContainer>
            </BottomSheetWrapper>
            <NavContainer>
                <NavItem
                    onClick={() => clickTab("tab")}
                    className={`${uri === "/" ? "active" : ""}`}
                >
                    <Map color={uri === "/" ? ACTIVE_COLOR : undefined} />
                    <span>지도보기</span>
                </NavItem>
                <NavItem
                    onClick={() => clickTab("request")}
                    className={`${uri.includes("request") ? "active" : ""}`}
                >
                    <List
                        color={
                            uri.includes("request") ? ACTIVE_COLOR : undefined
                        }
                    />
                    <span>목록보기</span>
                </NavItem>
                <NavItem
                    onClick={() => clickTab("blBeacon")}
                    className={`${uri.includes("beacon") ? "active" : ""}`}
                >
                    <BlBeacon
                        color={
                            uri.includes("beacon") ? ACTIVE_COLOR : undefined
                        }
                    />
                    <span>비콘 인식</span>
                </NavItem>
                <NavItem
                    onClick={() => clickTab("qrCamera")}
                    className={`${uri.includes("qr") ? "active" : ""}`}
                >
                    <QrCamera
                        color={uri.includes("qr") ? ACTIVE_COLOR : undefined}
                    />
                    <span>QR 인식</span>
                </NavItem>
                {/* <NavItem
                onClick={() => clickTab("complete")}
                className={`${uri.includes("complete") ? "active" : ""}`}
            >
                <Complete
                    color={uri.includes("complete") ? ACTIVE_COLOR : undefined}
                />
                <span>회수완료</span>
            </NavItem> */}
                <NavItem
                    onClick={() => clickTab("setting")}
                    className={`${uri.includes("setting") ? "active" : ""}`}
                >
                    <Setting
                        color={
                            uri.includes("setting") ? ACTIVE_COLOR : undefined
                        }
                    />
                    <span>마이페이지</span>
                </NavItem>
            </NavContainer>
        </>
    );
};

const NavItem = styled.div`
    cursor: pointer;
    width: 25%;
    height: 56px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    z-index: 1001;

    span {
        color: #abb4b9;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        line-height: 150%;
    }

    &.active {
        cursor: default;
        span {
            color: #008f41;
        }
    }
`;

const NavContainer = styled.div`
    position: fixed;
    display: flex;
    box-shadow: 0px -4px 10px 0px rgba(0, 96, 63, 0.06);
    align-items: center;
    background-color: #fff;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 600px;
    transform: translate(-50%, 0);
    z-index: 1001;
`;

const BottomSheetWrapper = styled.div`
    display: ${({ uri }) => (uri === "/" ? "block" : "none")};
    position: fixed;
    bottom: 56px;
    width: 100%;
    height: 56px;
    z-index: 999;
    max-width: 600px;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px 0px 20px 0px #2835861a;
`;

const BottomSheetContainer = styled.div`
    width: 100%;
`;

const BottomSheetBtn = styled.div`
    text-align: center;
`;

const BottomSheetContent = styled.div`
    cursor: pointer;
`;

const FlexItemTopWrap = styled.div`
    display: flex;
    margin-bottom: 16px;
    span.date {
        color: #656e6b;
        font-size: 17px;
        font-weight: 600;
        line-height: 150%; /* 16.5px */
        letter-spacing: -0.11px;
    }

    div.status-request-area {
        display: none;
    }

    div.status-complete-area {
        margin: 0 0 0 10px;
        padding: 4px 6px 4px 6px;
        border-radius: 3px;
        background-color: #afb4b2;
    }

    span.status_text {
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: #ffffff;
    }
`;

const FlexItemWrap = styled.div`
    display: flex;
    align-items: baseline;
    gap: 8px;
    &:first-of-type {
        margin-top: 0;
    }
    span.key {
        flex-basis: 60px;
        margin-top: 8px;
        color: var(--gray-1636-e-73, #656e6b);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }
    span.val {
        flex: 1;
        color: #222222;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
    }
`;

const FlexItemContainer = styled.div`
    cursor: pointer;

    div {
        margin-top: ${(props) => (props.$type === "list" ? 8 : 4)}px;
    }
`;

const RetrieveInfoWrap = styled.div`
    flex: 1;
    span {
        display: block;
    }
`;

const SheetItemContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 10px 20px;
    border-bottom: 1px solid #edeeed;
    border-radius: 10px;
    background: #ffffff;
    margin-bottom: 10px;
    &:last-of-type {
        display: block;
        height: 100%;
        margin-bottom: 0;
        overflow-y: auto;
        padding-bottom: 60px;
        -ms-overflow-style: none; /* 인터넷 익스플로러 */
        scrollbar-width: none; /* 파이어폭스 */

        ::-webkit-scrollbar {
            display: none;
        }
    }

    div.list-request-group {
        width: 28px;
        height: 28px;
        position: absolute;
        margin-top: 0;
        top: 35px;
        transform: translate(0, -50%);
        right: 35px;
        border-radius: 999px;
        padding: 7px 4px 7px 4px;
        background-color: #009ae5;
    }

    img.list-item-arrow {
        user-select: none;
        position: absolute;
        top: 35px;
        transform: translate(0, -50%);
        right: 4px;
    }

    span.list-item-text {
        text-align: center;
        font-family: Pretendard, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        color: #ffffff;
    }
`;

const TmapBtnWrap = styled.div`
    display: flex;
    margin: 25px 0 15px 0;
`;

const TmapButton = styled.button`
    width: 100%;
    height: 45px;
    background-color: #005779;

    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    border-radius: 100px;
    text-align: center;

    &.large {
        line-height: 1;
        padding: 18px 0;
        height: initial;
        &:disabled {
            background: var(--Gray-Scale-300-_D4D6D4, #d1d3d3);
        }
    }
`;

const BottomSheetText = styled.div`
    display: flex;
    padding: 15px 20px 12px 20px;
    justify-content: center;
    text-align: center;
    gap: 8px;

    h2 {
        font-family: Pretendard, sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        color: #222222;
    }

    p {
        font-family: Pretendard, sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        color: #f26700;
    }
`;

export default BottomTabNav;
