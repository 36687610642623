import React, { useEffect, useState } from "react";
import styled from "styled-components";
import dropdownArrow from "../../assets/images/icons/ic-dropdown-arrow.svg";
import refresh from "../../assets/images/icons/new-ic-refresh.svg";
import search from "../../assets/images/icons/ic-search.svg";
import {
    closeDatePicker,
    openDatePicker,
    updateDateState,
} from "../../redux/DatePickerModalSlice";
import { setSearchCategory, setSearchText } from "../../redux/RetrieveSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import DatepickerModal from "../DatepickerModal";
import { useNavigate, useLocation } from "react-router-dom";
import { closeConfirm, openConfirm } from "../../redux/ConfirmSlice";

const SearchBarDropdown = ({ options, getRetrieveItems }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedStartDate, selectedEndDate } = useSelector(
        (state) => state.datePicker
    );
    const { searchCategory, searchText } = useSelector(
        (state) => state.retrieve
    );

    const [selectedOption, setSelectedOption] = useState("이름");
    const [searchMenu, setSearchMenu] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        // 기간 선택 시 input 창에 기간 표시
        if (searchCategory === "기간" && selectedEndDate) {
            dispatch(
                setSearchText(
                    `${dayjs(selectedStartDate).format("YYYY.MM.DD")} ~ ${dayjs(
                        selectedEndDate
                    ).format("YYYY.MM.DD")}`
                )
            );
        }
    }, [selectedOption]);

    const handleChange = (e) => {
        const value = e.target.value;
        // 전화번호 하이픈 삽입 (유, 무선 전화번호 공용)
        if (searchCategory === "전화번호") {
            dispatch(
                setSearchText(
                    value
                        .replace(/[^0-9]/g, "") // 숫자를 제외한 모든 문자 제거
                        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                )
            );
        } else {
            dispatch(setSearchText(value));
        }
    };

    const handleDropdownChange = () => {
        setSearchMenu(!searchMenu);
    };

    const handleDropdownClick = (e) => {
        if (!e.target.innerText) return;
        setSelectedOption(e.target.innerText);
        // 검색 후 지도에서 리스트 페이지 전환 시 전역 변수로 드롭다운 메뉴 고정
        dispatch(setSearchCategory(e.target.innerText));
        // 기간 선택 시 input 창 read only
        if (e.target.innerText === "기간") {
            dispatch(setSearchText(""));
            dispatch(
                updateDateState({
                    selectedStartDate: null,
                    selectedEndDate: null,
                })
            );

            openDatePickerModal();
        } else {
            dispatch(setSearchText(""));
            clearSelectedDate();
            handleClose();
        }
    };

    const handleClick = () => {
        //검색 결과 로직(검색어 빈 값일 때 무응답)
        let uri = location.pathname + location.search;
        if (searchText !== "") {
            if (uri === "/") {
                navigate("/tab?status=request");
            } else {
                getRetrieveItems();
                setSearchMenu(false);
            }
        }
    };

    const handlePress = (e) => {
        if (e.key === "Enter") {
            handleClick();
        }
    };

    const handleDateSearch = (date) => {
        let uri = location.pathname + location.search;
        if (date[0] === null || date[1] === null) {
            dispatch(
                openConfirm({
                    confirmText: "확인",
                    isSingleButton: true,
                    title: "날짜를 선택해 주세요.",
                    confirmFunc: () => {
                        dispatch(closeConfirm());
                    },
                })
            );
        } else {
            if (uri === "/") {
                navigate("/tab?status=request");
            } else {
                getRetrieveItems(startDate, endDate);
                setSearchMenu(false);
            }
        }
    };

    const mapRefreshFunc = () => {
        window.location.reload();
    };

    const clearSelectedDate = () => {
        dispatch(
            updateDateState({ selectedStartDate: null, selectedEndDate: null })
        );
    };

    const openDatePickerModal = () => {
        dispatch(openDatePicker(""));
    };

    const handleClose = () => {
        dispatch(closeDatePicker());
    };

    const handleHolder = () => {
        if (searchCategory === "기간") return "날짜를 선택해 주세요.";
        else if (searchCategory === "전화번호")
            return "하이픈(-) 없이 숫자만 입력해 주세요.";
        else return "검색어를 입력해 주세요.";
    };

    return (
        <>
            <DropdownContainer>
                <Dropdown onClick={handleDropdownChange}>
                    <DropdownContent searchMenu={searchMenu}>
                        <DropdownText>{searchCategory}</DropdownText>
                        <img src={dropdownArrow} alt="Arrow" />
                    </DropdownContent>
                    <DropdownItemContainer>
                        {searchMenu &&
                            options.map((item, index) => (
                                <DropdownItem
                                    key={index}
                                    onClick={handleDropdownClick}
                                >
                                    {item.label}
                                </DropdownItem>
                            ))}
                    </DropdownItemContainer>
                </Dropdown>
            </DropdownContainer>
            <SearchContainer>
                <Input
                    type="text"
                    disabled={searchCategory === "기간" ? true : false}
                    value={searchText}
                    onChange={handleChange}
                    onKeyDown={handlePress}
                    placeholder={handleHolder()}
                />
                <SearchIcon src={search} alt="Search" onClick={handleClick} />
            </SearchContainer>
            <RefreshContainer onClick={mapRefreshFunc}>
                <RefreshIcon src={refresh} alt="Refresh" />
            </RefreshContainer>
            <DatepickerModal
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateSearch={handleDateSearch}
                selectedOption={selectedOption}
            />
        </>
    );
};

const DropdownContainer = styled.div`
    display: flex;
    width: 35%;
    max-width: 120px;
    max-height: 50px;
    background-color: white;
    padding: 0 4px 0 0;
    border: 1px solid #64c386;
    border-right: none;
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
    z-index: 499;
`;

const SearchContainer = styled.div`
    display: flex;
    width: 65%;
    max-width: 400px;
    max-height: 50px;
    background-color: white;
    padding: 0 4px 0 0;
    border: 1px solid #64c386;
    border-left: none;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
    z-index: 499;
`;

const Input = styled.input`
    width: 100%;
    padding: 15px;
`;

const Dropdown = styled.div`
    cursor: pointer;
    width: 100%;
    max-height: 150px;
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
`;

const DropdownItemContainer = styled.div`
    position: relative;
    width: 85%;
    top: 0;
    left: 10px;
`;

const DropdownItem = styled.li`
    padding: 10px 0 10px 15px;
    cursor: pointer;
    background-color: white;
    border: 1px solid #f0f0f0;
    &:hover {
        background-color: #f0f0f0;
    }
`;

const DropdownText = styled.p`
    display: flex;
    justify-content: center;
    padding: 10px 0 10px 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    color: #656e6b;
    width: 90px;
`;

const DropdownContent = styled.div`
    display: flex;
    align-items: center;
    height: 49px;
`;

const RefreshContainer = styled.div`
    display: flex;
    width: 50px;
    max-height: 50px;
    margin-left: 7px;
    padding: 12px;
    cursor: pointer;
    border-radius: 999px;
    border: 1px solid #d1d3d3;
    background-color: white;
    align-items: center;
    z-index: 499;
`;

const RefreshIcon = styled.img`
    width: 24px;
    height: 24px;
`;

const SearchIcon = styled.img`
    cursor: pointer;
    width: 32px;
    margin-right: 15px;
`;

export default SearchBarDropdown;
