import React, { useEffect, useState } from "react";
import { closeConfirm, openConfirm } from "../redux/ConfirmSlice";
import { useDispatch } from "react-redux";
import { Loading } from "./common";
import styled from "styled-components";

const AppUpdate = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // 로그인 페이지 로드 전 모바일 앱 버전 체크
        // (버전 상이할 경우 업데이트 모달 노출)ret.the-pureun.co.kr
        if (
            /iPhone|iPad/i.test(navigator.userAgent) ||
            /Android/i.test(navigator.userAgent)
        ) {
            setIsLoading(true);
            // Webview => Native
            function updateHandleEvent(message) {
                const updateMessage = JSON.parse(message.data);
                if (updateMessage.action === "update_app") {
                    updateCheck();
                }
                setIsLoading(false);

                window.removeEventListener("message", updateHandleEvent, true);
            }
            window.addEventListener("message", updateHandleEvent, true);
        }
    }, []);

    const updateCheck = () => {
        dispatch(
            openConfirm({
                title: (
                    <>
                        최신 버전이 존재합니다.
                        <br />
                        다운로드 하시겠습니까?
                    </>
                ),
                text: "더 나은 서비스를 위해 업데이트를 진행해주세요.",
                confirmText: "다운로드",
                notCloseOnClickDimmed: true,
                confirmFunc: () => {
                    completeRetrieve();
                },
                cancelText: "취소",
                cancelFunc: () => {
                    dispatch(closeConfirm());
                },
            })
        );
    };

    const completeRetrieve = () => {
        const data = {
            action: "update_app",
        };
        window["ReactNativeWebView"] &&
            window["ReactNativeWebView"].postMessage(JSON.stringify(data));
    };

    return (
        <UpdateCheck>
            <Loading isLoading={isLoading} subText="버전 체크 중..." />
        </UpdateCheck>
    );
};

const UpdateCheck = styled.div`
    position: relative;
    width: 100%;
    height: calc(120vh - 125px);
`;

export default AppUpdate;
