import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import SearchBarDropdown from "../common/SearchBarDropdown";
import { setSearchCategory, setSearchText } from "../../redux/RetrieveSlice";
import { useDispatch } from "react-redux";
import {
    closeDatePicker,
    updateDateState,
} from "../../redux/DatePickerModalSlice";

const TopSearchBar = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [listPage, setListPage] = useState(false);

    const clickTab = (to) => {
        dispatch(
            updateDateState({ selectedStartDate: null, selectedEndDate: null })
        );
        dispatch(setSearchText(""));
        dispatch(closeDatePicker());
        dispatch(setSearchCategory("이름"));
        navigate(to);
    };

    useEffect(() => {
        const item = location.pathname + location.search;
        // Tab 노출 여부
        if (item.includes("/tab?status=")) setListPage(true);
        else setListPage(false);
    }, [location]);

    const options = [
        { value: "name", label: "이름" },
        { value: "address", label: "주소" },
        { value: "tel", label: "전화번호" },
        { value: "date", label: "기간" },
    ];

    return (
        <>
            {listPage ? (
                <TabContainer>
                    <Tab
                        className={`${
                            location.search === "?status=request"
                                ? "active"
                                : ""
                        }`}
                        onClick={() => clickTab("/tab?status=request")}
                    >
                        <span>회수 요청</span>
                    </Tab>
                    <Tab
                        className={`${
                            location.search === "?status=complete"
                                ? "active"
                                : ""
                        }`}
                        onClick={() => clickTab("/tab?status=complete")}
                    >
                        <span>회수 완료</span>
                    </Tab>
                </TabContainer>
            ) : null}

            <TopContainer listPage={listPage}>
                <SearchBarDropdown
                    options={options}
                    getRetrieveItems={props.getRetrieveItems}
                />
            </TopContainer>
        </>
    );
};

const TopContainer = styled.div`
    position: ${({ listPage }) => (listPage ? "sticky" : "fixed")};
    margin-top: ${({ listPage }) => (listPage ? "20px" : "30px")};
    width: 100%;
    max-width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 990;
    drop-shadow: 0 0 12px 0 #28358614;
`;

const TabContainer = styled.div`
    display: flex;
    position: sticky;
    z-index: 991;
`;

const Tab = styled.div`
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 46px;
    width: 50%;
    z-index: 499;

    span {
        color: var(--gray-2-abb-4-b-9, #abb4b9);
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
    }

    &.active {
        span {
            color: #007d31;
        }
        &:after {
            content: "";
            width: 100%;
            height: 4px;
            display: block;
            position: absolute;
            left: 0;
            bottom: -2px;
            z-index: 2;
            border-bottom: 2px solid #00913a;
        }
    }
`;

export default TopSearchBar;
