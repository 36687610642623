import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ROUTES from "../constants/routes";

import Root from "./Root";
import Login from "./Login";
import RetrieveMap from "./RetrieveMap";
import RetrieveList from "./RetrieveList";
import RetrieveDetail from "./RetrieveDetail";
import VocSubmit from "./VocSubmit";
import Setting from "./Setting";
import QR from "./QR";
import BEACON from "./Beacon";
import TabPage from "./TabPages";
import MyPage from "./MyPage";

const route = () => {
    const router = createBrowserRouter([
        {
            path: ROUTES.ROOT,
            element: <Root />,
            children: [
                {
                    index: true,
                    element: <RetrieveMap />,
                },
                {
                    path: ROUTES.TAB,
                    element: <TabPage />,
                    children: [
                        {
                            index: true,
                            element: <RetrieveList />,
                        },
                        {
                            path: ROUTES.SETTING,
                            element: <Setting />,
                        },
                        {
                            path: ROUTES.MY_PAGE,
                            element: <MyPage />,
                        },
                        {
                            path: ROUTES.QR,
                            element: <QR />,
                        },
                        {
                            path: ROUTES.BEACON,
                            element: <BEACON />,
                        },
                    ],
                },
            ],
        },
        {
            path: ROUTES.VOC_SUBMIT,
            element: <VocSubmit />,
        },
        {
            path: ROUTES.RETRIEVE_DETAIL,
            element: <RetrieveDetail />,
        },
        {
            path: ROUTES.LOGIN,
            element: <Login />,
        },
    ]);

    return <RouterProvider router={router} />;
};

export default route;
