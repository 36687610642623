import React, { useEffect } from "react";
import styled from "styled-components";

import ImgDeliveryMan from "../assets/images/img-delivery-man@2x.png";
import IcPageArrow from "../assets/images/icons/ic-chevron-right.svg";

import { openConfirm, closeConfirm } from "../redux/ConfirmSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutSuccess, sessionSave } from "../redux/AuthSlice";
import accountManager from "../apis/accountManager";
import Routes from "../constants/routes";
import retrieveManager from "../apis/retrieveManager";

const Setting = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, sessionData } = useSelector((state) => state.auth);

    useEffect(() => {
        userLoginCheck();
    }, []);

    const userLoginCheck = async () => {
        const sessionCheck = await retrieveManager.sessionCheck(
            user,
            sessionData
        );

        if (!sessionCheck)
            return retrieveManager.confirmOpen(navigate, dispatch);
    };

    const openLogoutConfirm = () => {
        dispatch(
            openConfirm({
                title: "정말 로그아웃 하시겠습니까?",
                confirmText: "확인",
                cancelText: "취소",
                confirmFunc: async () => {
                    const result = await accountManager.logout();
                    dispatch(logoutSuccess()); // 로그인 정보 삭제
                    dispatch(closeConfirm());
                    dispatch(sessionSave(null)); // 세션 정보 삭제
                    navigate("/login");
                },
                cancelFunc: () => {
                    dispatch(closeConfirm());
                },
            })
        );
    };

    const moveToMyPage = () => {
        navigate(Routes.MY_PAGE);
    };

    return (
        <SettingPageContainer>
            <SettingHeaderWrap>마이페이지</SettingHeaderWrap>
            <NameContainer>
                <img src={ImgDeliveryMan} alt="delivery man img" />
                <p>
                    <span>{user?.nick_name}</span> 님, 감사합니다.
                </p>
            </NameContainer>
            <MenuUl>
                <MenuListItem onClick={moveToMyPage}>
                    <span>내 작업 현황</span>
                    <img src={IcPageArrow} alt="" />
                </MenuListItem>
                <MenuListItem onClick={openLogoutConfirm}>
                    <span>로그아웃</span>
                </MenuListItem>
            </MenuUl>
        </SettingPageContainer>
    );
};

const MenuUl = styled.ul``;

const MenuListItem = styled.li`
    position: relative;
    width: 100%;
    background-color: #fff;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 18px 12px 18px 20px;
    cursor: pointer;
    border-bottom: 1px solid #edeeed;

    img {
        position: absolute;
        right: 12px;
    }

    &:last-of-type {
        border-bottom: 0;
    }

    span {
        color: var(--main-black-222222, #222);
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }
`;

const NameContainer = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 8px solid #f5f5f5;

    img {
        width: 56px;
        display: block;
    }

    p {
        color: var(--main-black-222222, #222);
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;

        span {
            color: var(--Primary-700_-006828, #006828);
            font-size: 18px;
            font-weight: 700;
        }
    }
`;

const SettingHeaderWrap = styled.div`
    background-color: #fff;
    padding: 18px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    color: var(--main-black-222222, #222);
    text-align: center;

    border-bottom: 1px solid #edeeed;
`;

const SettingPageContainer = styled.div`
    height: calc(100dvh - 124px);
`;

export default Setting;
