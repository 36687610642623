const QrCamera = ({ color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
        >
            <path
                d="M22.375 18.2222C22.375 18.6937 22.1834 19.1459 21.8425 19.4793C21.5015 19.8127 21.039 20 20.5568 20H4.19318C3.71097 20 3.24851 19.8127 2.90753 19.4793C2.56656 19.1459 2.375 18.6937 2.375 18.2222V8.44444C2.375 7.97295 2.56656 7.52076 2.90753 7.18737C3.24851 6.85397 3.71097 6.66667 4.19318 6.66667H7.82955L9.64773 4H15.1023L16.9205 6.66667H20.5568C21.039 6.66667 21.5015 6.85397 21.8425 7.18737C22.1834 7.52076 22.375 7.97295 22.375 8.44444V18.2222Z"
                stroke={color}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.375 16C14.0319 16 15.375 14.6569 15.375 13C15.375 11.3431 14.0319 10 12.375 10C10.7181 10 9.375 11.3431 9.375 13C9.375 14.6569 10.7181 16 12.375 16Z"
                stroke={color}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

QrCamera.defaultProps = {
    color: "#ABB4B9",
};
export default QrCamera;
