import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import styled from "styled-components";

import retrieveManager from "../apis/retrieveManager";
import { useNavigate } from "react-router-dom";

import IcCircleWarning from "../assets/images/icons/ic_circle_warning.svg";

import QRViewFinder from "../components/QRViewFinder";
import { Loading } from "../components/common";
import DetailHeader from "../components/common/DetailHeader";
import { useDispatch, useSelector } from "react-redux";

const Qr = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState("basic");
    const { user, sessionData } = useSelector((state) => state.auth);

    useEffect(() => {
        userLoginCheck();
    }, []);

    const userLoginCheck = async () => {
        const sessionCheck = await retrieveManager.sessionCheck(
            user,
            sessionData
        );

        if (!sessionCheck)
            return retrieveManager.confirmOpen(navigate, dispatch);
    };

    const checkIsQRValid = async (result) => {
        setIsLoading(true);
        const scannedTag = result.text.split("/qrScan/")[1]; // 회수 제품명 텍스트 분리
        const data = await retrieveManager.checkQRScanTag(scannedTag);

        if (data.list && data.status === "success") {
            let itemObj = {};
            Object.assign(itemObj, {
                count: data.list.length,
                duplicate: data.list,
            });
            // 회수품(이름, 주소 동일) 상세 조회 데이터 전송
            navigate(`/retrieve-detail/${data.list[0].id}`, {
                state: { group: itemObj, action: "qrcode" },
            });
            setIsLoading(false);
        } else if (data.status === "not request") {
            setPage("not_request");
            setIsLoading(false);
        } else {
            setPage("fail");
            setIsLoading(false);
        }
    };

    return (
        <>
            <Loading isLoading={isLoading} subText="QR 인식하는 중" />
            <QrWrap>
                <DetailHeader title={"QR 인식"} />
                {page && page === "basic" ? (
                    <QrScanContainer>
                        <QrReader
                            ViewFinder={QRViewFinder}
                            onResult={(result, error) => {
                                if (!!result) {
                                    checkIsQRValid(result);
                                }
                            }}
                            style={{ width: "100%", height: "100%" }}
                            constraints={{
                                facingMode: "environment",
                            }}
                        />
                    </QrScanContainer>
                ) : null}

                {page && page === "fail" ? (
                    // 인식 실패 혹은 회수 요청 미접수 제품 화면입니다.
                    <QrContainerFail className="scan-fail">
                        <img src={IcCircleWarning} />
                        <h2>제품 인식에 실패했습니다.</h2>
                        <p>
                            비콘 인식 혹은 회수 요청 목록을 통해
                            <br />
                            다시 시도해주세요.
                        </p>
                    </QrContainerFail>
                ) : null}
                {page && page === "not_request" ? (
                    <QrContainerFail className="mismatch">
                        <img src={IcCircleWarning} />
                        <h2>회수 요청이 접수되지 않은 제품입니다.</h2>
                    </QrContainerFail>
                ) : null}
            </QrWrap>
        </>
    );
};

const QrWrap = styled.div`
    h1 {
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }
`;

const QrContainerFail = styled.div`
    height: calc(100dvh - 124px);
    padding: 0 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h2 {
        margin-top: 16px;
        color: var(--Black, #222);
        font-size: 18px;
        font-weight: 600;
        line-height: 145%; /* 26.1px */
    }

    p {
        margin-top: 8px;
        color: var(--Gray-Scale-800-_636E73, #656e6b);
        font-size: 14px;
        font-weight: 500;
        line-height: 160%;
    }
`;

const QrScanContainer = styled.div`
    width: 100%;
    height: calc(100dvh - 124px);

    & > section {
        height: 100%;
        & > div {
            padding-top: unset;
            height: 100%;
        }
    }
`;

export default Qr;
