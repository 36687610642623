import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import IcCheck from "../../assets/images/icons/ic-checkbox.svg";

const Checkbox = ({
    type,
    className,
    name,
    onClick,
    onChange,
    checked,
    id,
}) => {
    return (
        <StyledCheckbox
            className={className}
            name={name}
            type={type}
            onClick={onClick}
            onChange={onChange}
            id={id}
            checkImg={IcCheck}
            checked={checked}
        />
    );
};

const StyledCheckbox = styled.input`
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid var(--Gray-Scale-400-_CFD4D1, #c1c5c3);
    position: relative;

    &:checked {
        border-color: var(--Primary-500_-00913A, #00913a);
        background: var(--Primary-500_-00913A, #00913a);

        &::after {
            content: "";
            position: absolute;
            left: 1px;
            top: 1px;
            width: 100%;
            height: 100%;
            background-image: url(${(props) => props.checkImg});
            background-repeat: no-repeat;
        }
    }
`;

Checkbox.propTypes = {};
Checkbox.defaultProps = {
    type: "checkbox",
};

export default Checkbox;
