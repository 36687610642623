import React from "react";

const Setting = ({ color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
        >
            <path
                d="M20.125 20C20.125 17.2386 16.5433 15 12.125 15C7.70672 15 4.125 17.2386 4.125 20"
                stroke={color}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="12.125"
                cy="8"
                r="4"
                stroke={color}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

Setting.defaultProps = {
    color: "#ABB4B9",
};

export default Setting;
