import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { DELIVERY_STATUS_TEXT, DELIVERY_STATUS } from "../constants";

import List from "./svg/List";
import Complete from "./svg/Complete";

const COLOR_TYPE = {
    [DELIVERY_STATUS.REQUEST]: "#00A73C",
    [DELIVERY_STATUS.COMPLETE]: "#D4D6D4",
};

const StatusLabel = ({ status, size, className }) => {
    return (
        <StatusLabelWrap $status={status} $size={size} className={className}>
            {status === DELIVERY_STATUS.REQUEST ? (
                <List color={"#fff"} />
            ) : status === DELIVERY_STATUS.COMPLETE ? (
                <Complete color={"#fff"} />
            ) : null}
            <span>{DELIVERY_STATUS_TEXT[status]}</span>
        </StatusLabelWrap>
    );
};

const StatusLabelWrap = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${(props) => (props.$size === "l" ? "78px" : "68px")};
    height: ${(props) => (props.$size === "l" ? "78px" : "68px")};
    border-radius: 100px;
    background-color: ${(props) => COLOR_TYPE[props.$status]};

    img {
        display: block;
        width: 22px;
    }

    span {
        display: block;
        font-size: 12px;
        color: #fff;
    }
`;

StatusLabel.propTypes = {
    size: PropTypes.oneOf(["l", "m"]),
    status: PropTypes.oneOf([
        DELIVERY_STATUS.DELIVERY,
        DELIVERY_STATUS.REQUEST,
        DELIVERY_STATUS.COMPLETE,
        DELIVERY_STATUS.DUNNING,
        DELIVERY_STATUS.FAIL,
    ]),
};
StatusLabel.defaultProps = {
    size: "l",
};

export default StatusLabel;
