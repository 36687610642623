import axios from "axios";

const BASE_URL = process.env.BASE_URL;

const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 15000,
    withCredentials: true,
    headers: {
        "Access-Control-Allow-Origin": `*`,
        "Access-Control-Allow-Credentials": "true",
    },
});

instance.interceptors.request.use(
    (config) => {
        return config;
    },
    (e) => {
        return Promise.reject(e);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        console.log(error);
        if (
            error?.response?.status === 401 ||
            error?.response?.status === "401"
        ) {
            location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export { instance };
