import IcHeaderArrow from "../../assets/images/icons/ic-chevron-left.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const DetailHeader = ({ title, action }) => {
    const navigate = useNavigate();

    const moveToBack = () => {
        // 이전으로 이동 시 페이지 로드 딜레이 현상으로 회수품 요청 목록으로 강제 이동(비콘 인식)
        if (action === "beacon") navigate("/tab?status=request");
        else navigate(-1);
    };

    return (
        <DetailHeaderWrap>
            <button onClick={moveToBack}>
                <img src={IcHeaderArrow} alt="arrow icon" />
            </button>
            <h1>{title}</h1>
            <span></span>
        </DetailHeaderWrap>
    );
};

const DetailHeaderWrap = styled.div`
    position: relative;
    padding: 36px 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 96px; */
    background: #fff;
    border-bottom: 1px solid var(--Gray-Scale-100-_EDEEED, #edeeed);

    button {
        width: 32px;
        height: 32px;
    }

    img {
        display: block;
    }

    span {
        width: 25px;
        height: 1px;
    }
`;

export default DetailHeader;
