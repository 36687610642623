import React from "react";
import styled from "styled-components";

const BottomSheet = ({ isOpen, onClose, children }) => {
    // 카카오맵에서 보이는 영역의 데이터만 bottom sheet 노출
    return (
        <>
            {isOpen && <Overlay onClick={onClose} />}
            <Sheet isOpen={isOpen}>{children}</Sheet>
        </>
    );
};

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
`;

const Sheet = styled.div`
    position: fixed;
    bottom: 56px;
    width: 100%;
    height: ${({ isOpen }) => (isOpen ? "calc(100% - 132px)" : "62px")};
    background-color: white;
    box-shadow: 0px 0px 16px 0px rgba(40, 53, 134, 0.08);
    transition: height 0.3s;
    z-index: 1000;
    max-width: 600px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`;

export default BottomSheet;
