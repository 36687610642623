import React from "react";

const Map = ({ color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
        >
            <path
                d="M15.875 5V5.01M15.875 12L12.375 7C12.0381 6.39103 11.8659 5.70469 11.8754 5.00879C11.8849 4.3129 12.0758 3.63152 12.4293 3.032C12.7827 2.43248 13.2865 1.93555 13.8908 1.59032C14.4951 1.24509 15.179 1.06351 15.875 1.06351C16.571 1.06351 17.2549 1.24509 17.8592 1.59032C18.4635 1.93555 18.9673 2.43248 19.3207 3.032C19.6742 3.63152 19.8651 4.3129 19.8746 5.00879C19.8841 5.70469 19.7119 6.39103 19.375 7L15.875 12Z"
                stroke={color}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.375 3.75L6.875 3L0.875 6V19L6.875 16L12.875 19L18.875 16V14M6.875 3V16M12.875 14V19"
                stroke={color}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

Map.defaultProps = {
    color: "#ABB4B9",
};

export default Map;
