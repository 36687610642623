import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import BottomTabNav from "../components/layouts/BottomTabNav";

const TabPages = () => {
    return (
        <div>
            <Outlet></Outlet>
            {/* <BottomTabNav /> */}
        </div>
    );
};

export default TabPages;
