import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import StatusLabel from "../components/StatusLabel";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import IcHeaderArrow from "../assets/images/icons/ic-chevron-left.svg";
import IcCross from "../assets/images/icons/ic-cross.svg";

import { Button, Radio, Loading } from "../components/common";

// import retrieveManager from "../apis/retrieveManager";
import { useSelector } from "react-redux";
import { openConfirm, closeConfirm } from "../redux/ConfirmSlice";
import { useDispatch } from "react-redux";
import SelectBox from "../components/common/Selectbox";
import retrieveManager from "../apis/retrieveManager";

const VocSubmit = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [textAreaLength, setTextAreaLength] = useState(0);
    const [mainText, setMainText] = useState("");
    const [selectCategory, setSelectCategory] = useState("");
    const [imageList, setImageList] = useState([]);
    const [nameCheck, setNameCheck] = useState([]);
    const [userId, setUserId] = useState(0);
    const [itemId, setItemId] = useState([]);
    const user = useSelector((state) => state.auth.user);
    if (!user || !Object.keys(user).length) {
        return <Navigate to={"/login"} />;
    }
    const navigate = useNavigate();

    useEffect(() => {
        setItemId(state.item);
        setUserId(state.id);
    }, []);

    const moveToBack = () => {
        navigate(-1);
    };

    const vocRegister = () => {
        setIsLoading(true);
        let selected = document.querySelector(
            "input[type=radio][name=retrieveStatus]:checked"
        ).id;

        itemId.map((item) => {
            let formData = new FormData();
            formData.append("writer_id", userId);
            formData.append("content", mainText);
            formData.append("category", selectCategory);
            formData.append("retrieve_request_id", Number(item));
            formData.append("admin_check", false);
            formData.append("status", selected);
            for (let image of imageList) {
                formData.append("vocFile", image);
            }

            retrieveManager.vocRegister(formData).then((res) => {
                if (res && res.data === itemId[itemId.length - 1].toString()) {
                    setIsLoading(false);
                    navigate("/tab?status=request");
                }
            });
        });
        dispatch(closeConfirm());
    };

    const openSubmitConfirm = () => {
        if (!selectCategory) {
            dispatch(
                openConfirm({
                    confirmText: "확인",
                    isSingleButton: true,
                    title: "작성 사유를 선택해 주세요.",
                    confirmFunc: () => {
                        dispatch(closeConfirm());
                    },
                })
            );
        } else {
            dispatch(
                openConfirm({
                    title: (
                        <>
                            총 {itemId.length}건의 항목에 대한
                            <br />
                            VOC를 등록 하시겠습니까?
                        </>
                    ),
                    text: "등록 후 취소할 수 없습니다.",
                    confirmText: "등록",
                    confirmFunc: () => {
                        vocRegister();
                    },
                    cancelText: "취소",
                    cancelFunc: () => {
                        dispatch(closeConfirm());
                    },
                })
            );
        }
    };

    const handleSelectChange = (selected) => {
        setSelectCategory(selected);

        // 회수품 분실일 때 회수 완료 버튼 비활성
        if (selected === "missing") {
            document.querySelector(
                "input[type=radio][id=retrieveFail]"
            ).checked = true;
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    };

    const handleTextAreaChange = (e) => {
        setMainText(e.target.value);
        setTextAreaLength(e.target.value.length);
    };

    const uploadFunc = (e) => {
        setIsLoading(true);
        const fileData = e.currentTarget.files;
        const maxSize = 5 * 1024 * 1024;
        let fileArray = [];

        if (imageList.length + fileData.length > 5) {
            setIsLoading(false);
            dispatch(
                openConfirm({
                    confirmText: "확인",
                    isSingleButton: true,
                    title: "이미지 파일 첨부는 최대 5개까지 가능합니다.",
                    confirmFunc: () => {
                        dispatch(closeConfirm());
                    },
                })
            );
        }

        for (let i = 0; i < fileData.length; i++) {
            const filterData = imageList.filter(
                (item) => item.name === fileData[i].name
            );
            if (filterData.length > 0) {
                dispatch(
                    openConfirm({
                        confirmText: "확인",
                        isSingleButton: true,
                        title: "동일한 파일 이름이 존재합니다.",
                        confirmFunc: () => {
                            dispatch(closeConfirm());
                            setIsLoading(false);
                        },
                    })
                );
                break;
            }
            if (fileData[i].size > maxSize) {
                dispatch(
                    openConfirm({
                        confirmText: "확인",
                        isSingleButton: true,
                        title: "파일첨부 사이즈는 5MB 이내로 가능합니다.",
                        confirmFunc: () => {
                            dispatch(closeConfirm());
                            setIsLoading(false);
                        },
                    })
                );
                break;
            }

            fileArray.push(fileData[i]);
        }
        setImageList([...imageList, ...fileArray]);
        setIsLoading(false);
    };

    const CAUSE_OPTIONS = [
        { value: "damaged", name: "파손" },
        { value: "missing", name: "회수품 없음" },
        { value: "lack", name: "수량부족" },
    ];

    // 이미지 파일 목록에서 제거
    const deleteFunc = (name) => {
        const filterData = imageList.filter((item) => item.name !== name);
        setImageList(filterData);
    };

    return (
        <VocSubmitPageContainer>
            <Loading isLoading={isLoading} subText={"이미지 업로드 중"} />
            <DetailHeaderWrap>
                <button onClick={moveToBack}>
                    <img src={IcHeaderArrow} alt="arrow icon" />
                </button>
                <h1>VOC 접수</h1>
                <span></span>
            </DetailHeaderWrap>
            <section>
                <StatusWrapper>
                    <legend>박스 회수 상태</legend>

                    <div className="radio-item-wrapper">
                        <RadioItem>
                            <Radio
                                type="radio"
                                id="retrieveFail"
                                name="retrieveStatus"
                                value="fail"
                                checked={false}
                            />
                            <label htmlFor="retrieveFail">회수 실패</label>
                        </RadioItem>

                        <RadioItem>
                            <Radio
                                type="radio"
                                id="retrieveSuccess"
                                name="retrieveStatus"
                                value="success"
                                checked={true}
                                disabled={isDisable}
                            />
                            <label htmlFor="retrieveSuccess">회수 완료</label>
                        </RadioItem>
                    </div>
                </StatusWrapper>

                <div className="content-wrapper">
                    <SelectBox
                        name="voc"
                        options={CAUSE_OPTIONS}
                        defaultValue={"작성 사유를 선택해 주세요."}
                        handleSelectChange={handleSelectChange}
                    ></SelectBox>
                    <div className="textarea-wrapper">
                        <textarea
                            maxLength={500}
                            placeholder="상세 내용 입력"
                            onChange={handleTextAreaChange}
                        ></textarea>
                        <p className="textarea-length">
                            {textAreaLength} / 500
                        </p>
                    </div>
                </div>

                <FileUploadBtnWrapper>
                    <div className="input-container">
                        <div className="input-wrapper">
                            <label htmlFor="voc-file-input">첨부 파일</label>
                            <input
                                type="file"
                                id="voc-file-input"
                                name="voc-file-input"
                                multiple
                                accept="image/jpg, image/png, image/gif"
                                onChange={uploadFunc}
                            />
                        </div>
                        <p>
                            5MB 이하의 이미지 파일 최대 5개
                            <br />
                            (jpg, png, gif)
                        </p>
                    </div>
                    <Swiper
                        className="upload-file-swiper"
                        spaceBetween={6}
                        slidesPerView={"auto"}
                    >
                        {imageList &&
                            imageList.map((el, idx) => (
                                <SwiperSlide key={idx}>
                                    {el.name}
                                    <button>
                                        <img
                                            src={IcCross}
                                            alt="delete"
                                            onClick={() => deleteFunc(el.name)}
                                        />
                                    </button>
                                </SwiperSlide>
                            ))}
                    </Swiper>
                </FileUploadBtnWrapper>
            </section>
            <ButtonWrap>
                <Button className={"large"} onClick={openSubmitConfirm}>
                    등록하기
                </Button>
            </ButtonWrap>
        </VocSubmitPageContainer>
    );
};

const FileUploadBtnWrapper = styled.div`
    .input-container {
        padding: 0 20px;
        display: flex;
        align-items: center;
        gap: 12px;

        .input-wrapper {
            label {
                display: block;
                width: fit-content;
                padding: 12px;
                border-radius: 4px;
                border: 1px solid var(--Gray-Scale-400-_CFD4D1, #c1c5c3);
                color: var(--Gray-Scale-900_-36423E, #36423e);
                font-size: 14px;
                font-weight: 500;
                line-height: 100%; /* 14px */
            }

            input[type="file"] {
                display: block;
                width: 0;
                height: 0;
                min-width: 0;
                overflow: hidden;
                line-height: 0;
                margin: 0;
                padding: 0;
                border: 0;
                outline: 0;
            }
        }

        p {
            color: var(--Gray-Scale-600-_ABB4B9, #9ba19f);
            font-size: 13px;
            font-weight: 400;
            line-height: 120%; /* 15.6px */
            letter-spacing: -0.01em;
        }
    }

    .upload-file-swiper {
        padding: 0 20px;
        margin-top: 24px;
        .swiper-slide {
            width: auto;
            border-radius: 999px;
            border: 1px solid var(--Gray-Scale-200_-DFE1E0, #dfe1e0);
            padding: 8px 10px;
            background: #fafafa;
            color: var(--Gray-Scale-800-_636E73, #656e6b);
            font-size: 13px;
            font-weight: 400;
            line-height: 100%; /* 13px */
            display: flex;
            align-items: center;

            button {
                margin-left: 4px;
            }
        }
    }
`;

const RadioItem = styled.div`
    display: flex;
    align-items: center;
    input + label {
        margin-left: 8px;
    }
`;

const StatusWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 20px;
    border-bottom: 1px solid var(--Gray-Scale-100-_EDEEED, #edeeed);

    legend,
    label {
        color: var(--Black, #222);
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
    }

    .radio-item-wrapper {
        display: flex;
        gap: 32px;
    }
`;

const ButtonWrap = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    gap: 8px;
    padding: 0 16px 24px;
`;

const DetailHeaderWrap = styled.div`
    position: relative;
    padding: 36px 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 96px; */
    background: #fff;
    border-bottom: 1px solid var(--Gray-Scale-100-_EDEEED, #edeeed);

    h1 {
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }

    button {
        width: 32px;
        height: 32px;
    }

    img {
        display: block;
    }

    span {
        width: 25px;
        height: 1px;
    }
`;

const VocSubmitPageContainer = styled.div`
    overflow: auto;
    height: 100dvh;

    .content-wrapper {
        padding: 20px 20px 10px;

        .textarea-wrapper {
            position: relative;

            textarea {
                border: 1px solid var(--Gray-Scale-300-_D4D6D4, #d1d3d3);
                background: #fff;
                border-radius: 4px;
                margin-top: 10px;
                width: 100%;
                padding: 16px 16px 37px;
                min-height: calc(100dvh - 431px);

                font-size: 15px;
                font-weight: 400;
                line-height: 150%; /* 22.5px */

                &::placeholder {
                    color: var(--Gray-Scale-600-_ABB4B9, #9ba19f);
                }
            }

            .textarea-length {
                color: var(--Gray-Scale-600-_ABB4B9, #9ba19f);
                font-size: 13px;
                font-weight: 400;
                line-height: 100%; /* 13px */
                background: #fff;
                width: calc(100% - 32px);
                position: absolute;
                left: 16px;
                bottom: 4px;
                text-align: right;
                padding: 8px 0 16px;
            }
        }
    }

    .input-file-wrapper {
        padding: 0 20px;

        input[type="file"] {
        }
    }
`;
export default VocSubmit;
