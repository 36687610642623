import { configureStore } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import toastReducer from "./ToastSlice";
import datePickerModalReducer from "./DatePickerModalSlice";
import authReducer from "./AuthSlice";
import confirmReducer from "./ConfirmSlice";
import retrieveReducer from "./RetrieveSlice";

const persistConfig = {
    key: "root",
    storage,
    whitelist: [
        "auth",
        "user",
        "sessionData",
        "rememberPassword",
        "userInputPassword",
    ],
    version: 1,
};

const persistedReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
    reducer: {
        datePicker: datePickerModalReducer,
        confirm: confirmReducer,
        auth: persistedReducer,
        retrieve: retrieveReducer,
        toast: toastReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);
