import React from "react";
import styled from "styled-components";

const QRViewFinder = () => {
    return (
        <QrViewFinderWrap>
            <svg xmlns="http://www.w3.org/2000/svg" width="244" height="244" viewBox="0 0 244 244" fill="none" >
                <path d="M18 2H12.1587C9.46447 2 6.88055 3.07029 4.97542 4.97542C3.07029 6.88055 2 9.46447 2 12.1587V18" stroke="#64C386" strokeWidth="4" strokeLinecap="round" />
                <path d="M226 2H231.841C234.536 2 237.119 3.07029 239.025 4.97542C240.93 6.88055 242 9.46447 242 12.1587V18" stroke="#64C386" strokeWidth="4" strokeLinecap="round" />
                <path d="M242 226V231.841C242 234.536 240.93 237.119 239.025 239.025C237.119 240.93 234.536 242 231.841 242H226" stroke="#64C386" strokeWidth="4" strokeLinecap="round" />
                <path d="M18 242H12.1587C9.46447 242 6.88055 240.93 4.97542 239.025C3.07029 237.119 2 234.536 2 231.841V226" stroke="#64C386" strokeWidth="4" strokeLinecap="round" />
                <path d="M122 110L122 134" stroke="#64C386" strokeWidth="4" strokeLinecap="round" />
                <path d="M134 122L110 122" stroke="#64C386" strokeWidth="4" strokeLinecap="round" />
            </svg>
            <p>사각형 안에 QR코드를 맞춰주세요.</p>
        </QrViewFinderWrap>
    );
};

const QrViewFinderWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

        svg {
            width: 64%;
            height: auto;
        }

        p {
            margin-top: 32px;
            color: #fff;
            text-align: center;
            font-size: 17px;
            font-weight: 600;
            line-height: 100%; /* 17px */
        }
`;

export default QRViewFinder;
