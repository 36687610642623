export const listItems = (item, resultItems) => {
    // 동일한 이름, 주소의 회수품 체크 (상세 페이지 조회)
    let objItem = {
        id: "",
        Asset: {},
        retrieve_location: "",
        retrieve_location_detail: "",
        status: "",
        user_address: "",
        user_detail_address: "",
        user_id: "",
        user_name: "",
        user_phone_number: "",
        user_post_code: "",
    };

    resultItems.push(
        Object.assign(item[0], {
            count: item.length,
            duplicate: item.map(
                (dupItem) =>
                    (objItem = {
                        id: dupItem.id,
                        Asset: dupItem.Asset,
                        entry_way: dupItem.entry_way,
                        entry_way_detail: dupItem.entry_way_detail,
                        retrieve_location: dupItem.retrieve_location,
                        retrieve_location_detail:
                            dupItem.retrieve_location_detail,
                        status: dupItem.status,
                        user_address: dupItem.user_address,
                        user_detail_address: dupItem.user_detail_address,
                        user_id: dupItem.user_id,
                        user_name: dupItem.user_name,
                        user_phone_number: dupItem.user_phone_number,
                        user_post_code: dupItem.user_post_code,
                    })
            ),
        })
    );

    return resultItems;
};
