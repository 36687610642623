import React, { useEffect } from "react";
import styled from "styled-components";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import "../assets/stylesheets/app.scss";
import WorkerIcon from "../components/WorkerIcon";
import Header from "../components/layouts/Header";
import { useDispatch, useSelector } from "react-redux";
import BottomTabNav from "../components/layouts/BottomTabNav";
import { closeConfirm, openConfirm } from "../redux/ConfirmSlice";

const Root = () => {
    const user = useSelector((state) => state.auth.user);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        // Route Stack 없으면 뒤로가기 시 앱 종료
        if (
            /iPhone|iPad/i.test(navigator.userAgent) ||
            /Android/i.test(navigator.userAgent)
        ) {
            // Webview => Native
            function closeHandleEvent(message) {
                const closeMessage = JSON.parse(message.data);

                if (closeMessage.action === "close_app") {
                    dispatch(
                        openConfirm({
                            confirmText: "종료",
                            cancelText: "취소",
                            title: "앱을 종료하시겠습니까?",
                            confirmFunc: () => {
                                dispatch(closeConfirm());

                                const data = {
                                    action: "close_app",
                                };
                                window.ReactNativeWebView.postMessage(
                                    JSON.stringify(data)
                                );
                            },
                            cancelFunc: () => {
                                dispatch(closeConfirm());
                            },
                        })
                    );
                }
                window.removeEventListener("message", closeHandleEvent, true);
            }

            window.addEventListener("message", closeHandleEvent, true);
        }
    }, [location]);

    if (!user || !Object.keys(user).length) {
        return <Navigate to={"/login"} />;
    }

    return (
        <PageContainer>
            <Header />
            <Outlet />
            <BottomTabNav />
        </PageContainer>
    );
};

const PageContainer = styled.article`
    background-color: #fff;
    height: 100vh;
    border-radius: 4px;
    max-width: 600px;
    margin: 0 auto;
`;

export default Root;
