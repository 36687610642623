import React, { useState, useEffect } from "react";
import styled from "styled-components";

import IcHeaderArrow from "../assets/images/icons/ic-chevron-right.svg";
import MyList from "../components/MyList";

import retrieveManager from "../apis/retrieveManager";
import IcCalendar from "../assets/images/icons/ico_calendar.svg";
import { closeDatePicker, openDatePicker } from "../redux/DatePickerModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatepickerModal from "../components/DatepickerModal";

const MyPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, sessionData } = useSelector((state) => state.auth);
    const [myRetrieves, setMyRetrieves] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        getMyRetrieveList();
    }, []);

    const handleDateSearch = (date) => {
        let uri = location.pathname + location.search;
        if (uri === "/") {
            navigate("/tab?status=request");
        } else {
            getMyRetrieveList(startDate, endDate);
            dispatch(closeDatePicker());
        }
    };

    const openDatePickerModal = () => {
        dispatch(openDatePicker(""));
    };

    const moveToBack = () => {
        dispatch(closeDatePicker());
        navigate(-1);
    };

    const getMyRetrieveList = async (s_date, e_date) => {
        const result = await retrieveManager.getMyList(user.id, s_date, e_date);
        setMyRetrieves(result);
    };

    return (
        <MyPageContainer>
            <MyPageHeaderWrap>
                <button onClick={moveToBack}>
                    <img src={IcHeaderArrow} alt="header arrow icon" />
                </button>
                <span>내 작업 현황</span>
                <CalendarWrap onClick={openDatePickerModal}>
                    <img src={IcCalendar} alt="refresh icon" />
                </CalendarWrap>
            </MyPageHeaderWrap>
            <ListContainer>
                {myRetrieves &&
                    Object.keys(myRetrieves).map((item, index) => (
                        <CustomMyList
                            key={index}
                            item={myRetrieves[item]}
                            date={item}
                        />
                    ))}
            </ListContainer>
            <MypageDatePickerWrap>
                <DatepickerModal
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    dateSearch={handleDateSearch}
                />
            </MypageDatePickerWrap>
        </MyPageContainer>
    );
};

const CustomMyList = styled(MyList)`
    & + & {
        margin-top: 16px;
    }
`;

const ListContainer = styled.div`
    height: calc(100% - 53px);
    padding: 16px 16px 30px;
    overflow: auto;
`;

const MyPageContainer = styled.div`
    height: calc(100dvh - 114px);
    background: #fafafa;
`;

const CalendarWrap = styled.div`
    cursor: pointer;
    border: 1px solid #dfe1e0;
    background-color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    span.date {
        color: #bec5c8;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.13px;

        &.selected {
            color: #000;
        }
    }
`;

const MyPageHeaderWrap = styled.div`
    background-color: #fff;
    padding: 8px 12px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    z-index: 499;

    color: var(--main-black-222222, #222);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    border-bottom: 1px solid #edeeed;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;

        img {
            display: block;
            transform: rotate(180deg);
        }
    }

    //div {
    //    width: 40px;
    //    height: 40px;
    //}
`;

const MypageDatePickerWrap = styled.div`
    position: relative;
    z-index: 498;
`;

export default MyPage;
