import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Button = ({ children, type, size, className, onClick }) => {
    return (
        <StyledButton className={className} type={type} onClick={onClick}>
            {children}
        </StyledButton>
    );
};

const StyledButton = styled.button`
    width: 100%;
    height: 45px;
    background-color: #007D31;

    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    border-radius: 100px;
    text-align: center;

    &.large {
        line-height: 1;
        padding: 18px 0;
        height: initial;
        &:disabled {
            background: var(--Gray-Scale-300-_D4D6D4, #D1D3D3);
        }
        &.type2 {
            border: 1px solid var(--Primary-600_-007D31, #007D31);
            background: var(--White, #FFF);
            color: var(--Primary-600_-007D31, #007D31);
            &:disabled {
                border-color: var(--Gray-Scale-300-_D4D6D4, #D1D3D3);
                color: var(--Gray-Scale-300-_D4D6D4, #D1D3D3);
            }
        }
    }

`;

Button.propTypes = {};
Button.defaultProps = {
    type: "button",
};

export default Button;
