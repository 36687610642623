import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import IcPassword from "../../assets/images/icons/ic-password.svg";
import IcUser from "../../assets/images/icons/ic-user.svg";

const Input = ({
    onChange,
    value,
    placeHolder,
    isDisabled,
    maxLength,
    type,
    imageType,
    className,
}) => {
    return (
        <InputWrap className={className}>
            {imageType ? (
                <img
                    src={
                        imageType === "user"
                            ? IcUser
                            : imageType === "password"
                            ? IcPassword
                            : null
                    }
                    alt=""
                />
            ) : null}

            <input
                type={type}
                value={value}
                onChange={onChange}
                placeholder={placeHolder}
                disabled={isDisabled}
            />
        </InputWrap>
    );
};

const InputWrap = styled.div`
    position: relative;
    font-size: 0;
    width: 100%;
    height: 54px;
    text-align: left;

    img {
        display: block;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(0, -50%);
    }

    input {
        padding-left: 64px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 40px;
        border: 1px solid #fff;
        font-size: 14px;
        color: #000;
        &:focus {
            border-color: #008f41;
        }
    }
`;

Input.propTypes = {};
Input.defaultProps = {
    type: "text",
};

export default Input;
