import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    // selectedDate: new Date().toISOString().split("T")[0],
    selectedDate: null,
    selectedStartDate: null,
    selectedEndDate: null,
    startDateLimit: null,
    endDateLimit: null,
};

const datePickerModalSlice = createSlice({
    name: "datePickerModal",
    initialState,
    reducers: {
        openDatePicker: (state, action) => {
            state.isOpen = true;
        },
        updateDateState: (state, action) => {
            state.selectedStartDate = action.payload.selectedStartDate;
            state.selectedEndDate = action.payload.selectedEndDate;
        },
        closeDatePicker: (state, action) => {
            if (action.payload) {
                return initialState;
            } else {
                return { ...state, isOpen: false };
            }
        },
    },
});

export const { updateDateState, openDatePicker, closeDatePicker } =
    datePickerModalSlice.actions;
export default datePickerModalSlice.reducer;
