const ROUTES = {
    ROOT: "/",
    LOGIN: "/login",
    RETRIEVE_LIST: "/tab/list",
    RETRIEVE_DETAIL: "/retrieve-detail/:id",
    SETTING: "/tab/setting",
    QR: "/tab/qr",
    BEACON: "/tab/beacon",
    TAB: "/tab",
    MY_PAGE: "/tab/setting/my-page",
    VOC_SUBMIT: "/voc",
};

export default ROUTES;
