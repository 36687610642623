import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    sessionData: null,
    rememberPassword: false,
    userInputPassword: "",
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        // 로그인 유지를 위한 세션 정보 저장
        sessionSave: (state, action) => {
            state.sessionData = action.payload;
        },
        loginSuccess: (state, action) => {
            state.user = action.payload;
        },
        logoutSuccess: (state) => {
            state.user = null;
        },
        setUserInputPassword: (state, action) => {
            state.userInputPassword = action.payload;
        },
        toggleRememberPassword: (state, action) => {
            state.rememberPassword = action.payload;
        },
    },
});

export const {
    sessionSave,
    loginSuccess,
    logoutSuccess,
    setUserInputPassword,
    toggleRememberPassword,
} = authSlice.actions;

export default authSlice.reducer;
