import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Radio = ({ type, className, onClick, id, name, checked, disabled }) => {
    return (
        <StyledRadioButton
            className={className}
            type={type}
            onClick={onClick}
            id={id}
            name={name}
            defaultChecked={checked}
            disabled={disabled}
        />
    );
};

const StyledRadioButton = styled.input`
    width: 16px;
    height: 16px;
    border-radius: 20px;
    border: 1px solid var(--Gray-Scale-400-_CFD4D1, #c1c5c3);
    position: relative;

    &:checked {
        border-color: var(--Primary-500_-00913A, #00913a);

        &::after {
            content: "";
            position: absolute;
            left: 3px;
            top: 3px;
            width: 8px;
            height: 8px;
            border-radius: 20px;
            background: var(--Primary-500_-00913A, #00913a);
        }
    }
`;

Radio.propTypes = {};
Radio.defaultProps = {
    type: "radio",
};

export default Radio;
