import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import RetrieveListItem from "../components/RetrieveListItem";

import { useDispatch, useSelector } from "react-redux";

import retrieveManager from "../apis/retrieveManager";
import TopSearchBar from "../components/layouts/TopSearchBar";
import { listItems } from "../components/common/ListItems";
import dayjs from "dayjs";

const RetrieveList = () => {
    const dispatch = useDispatch();
    const [stateRetrieveItems, setStateRetrieveItems] = useState([]);
    const { user, sessionData } = useSelector((state) => state.auth);
    const { selectedStartDate, selectedEndDate } = useSelector(
        (state) => state.datePicker
    );
    const { searchCategory, searchText } = useSelector(
        (state) => state.retrieve
    );

    const [searchParams] = useSearchParams();
    const status = searchParams.get("status");
    const navigate = useNavigate();

    useEffect(() => {
        getRetrieveItems();
    }, [status]);

    const moveToDetail = (item) => {
        // 회수품(이름, 주소 동일) 상세 조회 데이터 전송
        navigate(`/retrieve-detail/${item.id}`, {
            state: { group: item, action: "list" },
        });
    };

    const getRetrieveItems = async (startDate, endDate) => {
        const sessionCheck = await retrieveManager.sessionCheck(
            user,
            sessionData
        );

        if (!sessionCheck)
            return retrieveManager.confirmOpen(navigate, dispatch);

        let payload = {
            status: status,
            user_id: user.id,
        };

        if (selectedStartDate && selectedEndDate) {
            payload.s_date = selectedStartDate;
            payload.e_date = selectedEndDate;
        }

        if (startDate && endDate) {
            payload.s_date = startDate;
            payload.e_date = endDate;
        }

        if (searchCategory && searchText) {
            payload.category = searchCategory;
            payload.text = searchText;
        }

        const result = await retrieveManager.getList(payload);
        let resultItems = [];
        let idCheck = [];

        result.map((item) => {
            const filterItems = result.filter((dupItem) => {
                if (
                    item.user_name === dupItem.user_name &&
                    item.lat === dupItem.lat &&
                    item.lng === dupItem.lng
                ) {
                    return dupItem;
                }
            });

            if (!idCheck.includes(filterItems[0].id)) {
                idCheck.push(filterItems[0].id);
                listItems(filterItems, resultItems);
            }
        });

        if (status === "complete") {
            // 최초 로드 시 당일 회수 완료 데이터만 조회 (검색 제외)
            if (!searchText && !selectedStartDate && !startDate) {
                resultItems = resultItems.filter(
                    (item) =>
                        dayjs().diff(
                            dayjs(item.completed_at).format("YYYY-MM-DD"),
                            "days"
                        ) === 0
                );
            }

            // 회수 완료 Tab 날짜 순 정렬
            resultItems = resultItems.sort((a, b) => {
                return new Date(b.completed_at) - new Date(a.completed_at);
            });
        }

        setStateRetrieveItems(resultItems);
    };

    return (
        <ListPageContainer>
            <TopSearchBarContainer>
                <TopSearchBar getRetrieveItems={getRetrieveItems} />
            </TopSearchBarContainer>
            <RetrieveItemContainer>
                {stateRetrieveItems &&
                    stateRetrieveItems.map((item) => (
                        <RetrieveListItem
                            key={item.id}
                            item={item}
                            type={"list"}
                            onClickFunc={() => moveToDetail(item)}
                        />
                    ))}
            </RetrieveItemContainer>
        </ListPageContainer>
    );
};

const ListPageContainer = styled.div`
    overflow: auto;
    background: #ffffff;
    padding: 16px 8px;
    width: 100%;
    height: calc(100dvh - 125px);
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */

    ::-webkit-scrollbar {
        display: none;
    }

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 20px;
    }
`;

const RetrieveItemContainer = styled.div`
    padding-top: 20px;
`;

const TopSearchBarContainer = styled.div`
    justify-content: center;
`;

export default RetrieveList;
