const Complete = ({ color }) => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="ico_success">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M7.18164 10.4999L10.0453 13.3635L14.818 7.63623"
                        stroke={color}
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M11.0001 19.091C15.7447 19.091 19.591 15.2447 19.591 10.5001C19.591 5.75546 15.7447 1.90918 11.0001 1.90918C6.25546 1.90918 2.40918 5.75546 2.40918 10.5001C2.40918 15.2447 6.25546 19.091 11.0001 19.091Z"
                        stroke={color}
                        strokeWidth="1.4"
                    />
                </g>
            </g>
        </svg>
    );
};

Complete.defaultProps = {
    color: "#ABB4B9",
};

export default Complete;
