import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    retrieveItems: [],
    completeRetrieveItems: [],
    requestRetrieveItems: [],
    myRetrieveItems: [],
    searchCategory: "이름",
    searchText: "",
};

const retrieveSlice = createSlice({
    name: "retrieve",
    initialState,
    reducers: {
        setRetrieveItems: (state, action) => {
            state.retrieveItems = action.payload;
        },
        setCompleteRetrieveItems: (state, action) => {
            state.completeRetrieveItems = action.payload;
        },
        setRequestRetrieveItems: (state, action) => {
            state.requestRetrieveItems = action.payload;
        },
        setSearchCategory: (state, action) => {
            state.searchCategory = action.payload;
        },
        setSearchText: (state, action) => {
            state.searchText = action.payload;
        },
    },
});

export const {
    setRetrieveItems,
    setCompleteRetrieveItems,
    setRequestRetrieveItems,
    setSearchCategory,
    setSearchText,
} = retrieveSlice.actions;

export default retrieveSlice.reducer;
