import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import { Button } from "../components/common";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import {
    updateDateState,
    closeDatePicker,
} from "../redux/DatePickerModalSlice";
import { setSearchText } from "../redux/RetrieveSlice";
import dayjs from "dayjs";

const currentDate = new Date();

/* 기존 캘린더 조회 기간 제한(사용 안함)
const oneYearLater = new Date(
    currentDate.getFullYear() + 1,
    currentDate.getMonth(),
    currentDate.getDate()
);
let oneMonthAgo;
if (currentDate.getMonth() === 0) {
    oneMonthAgo = new Date(
        currentDate.getFullYear() - 1,
        11,
        currentDate.getDate()
    );
} else {
    oneMonthAgo = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        currentDate.getDate()
    );
} */

const DatepickerModal = (props, { className }) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [path, setPath] = useState("");
    const [startDate, endDate] = dateRange;

    const dispatch = useDispatch();
    const { isOpen } = useSelector((state) => state.datePicker);

    useEffect(() => {
        setPath(location.href);
        setDateRange([null, null]);
    }, [props.selectedOption]);

    const handleDateChange = (date) => {
        props.setStartDate(date[0]);
        props.setEndDate(date[1]);
        if (date[0] && date[1]) {
            dispatch(
                setSearchText(
                    `${dayjs(date[0]).format("YYYY.MM.DD")} ~ ${dayjs(
                        date[1]
                    ).format("YYYY.MM.DD")}`
                )
            );
        }
        setDateRange(date);
    };

    // 기간 초기화 이벤트
    const clearSelectedDate = () => {
        dispatch(setSearchText(""));
        setDateRange([null, null]);
        dispatch(
            updateDateState({ selectedStartDate: null, selectedEndDate: null })
        );
    };

    const handleClose = () => {
        dispatch(closeDatePicker(false));
    };

    const submit = async () => {
        await dispatch(
            updateDateState({
                selectedStartDate: dayjs(startDate).format("YYYY-MM-DD"),
                selectedEndDate: dayjs(endDate).format("YYYY-MM-DD"),
            })
        );
        await props.setStartDate("");
        await props.setEndDate("");
        await props.dateSearch(dateRange);
        await setDateRange([null, null]);
        if (startDate !== null && endDate !== null) await handleClose();
    };

    const renderDayContents = (day, date) => {
        const isSelected =
            (startDate && date.getTime() === startDate.getTime()) ||
            (endDate && date.getTime() === endDate.getTime());
        return <div aria-selected={isSelected}>{day}</div>;
    };

    if (!isOpen) {
        return null;
    }

    const clickDimmer = (e) => {
        if (isOpen && e.currentTarget === e.target) {
            dispatch(closeDatePicker());
            setDateRange([null, null]);
        }
    };

    return (
        <Dimmer className={className} onClick={clickDimmer}>
            <ModalContainer path={path}>
                <div className="current-date">
                    {dayjs(currentDate).format("YYYY년 MM월 DD일")}
                </div>
                <DatePicker
                    inline
                    selected={startDate}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
                    dateFormat="yyyy-MM-dd"
                    dateFormatCalendar={"yyyy.MM"}
                    // minDate={startDateLimit || oneMonthAgo}
                    // maxDate={endDateLimit || oneYearLater}
                    showMonthYearDropdown
                    onChange={handleDateChange}
                    renderDayContents={renderDayContents}
                />
                <ClearButton onClick={clearSelectedDate}>초기화</ClearButton>
                <SearchDateButton onClick={submit}>검색</SearchDateButton>
            </ModalContainer>
        </Dimmer>
    );
};

const CloseButton = styled.button`
    position: absolute;
    right: 16px;
    top: -60px;
    box-shadow: 0 0 6px 0 rgba(0, 96, 63, 0.1);

    img {
        display: block;
    }
`;

const ClearButton = styled(Button)`
    width: 52px;
    color: #636e73;
    background-color: white;
    float: right;
`;

const SearchDateButton = styled(Button)`
    width: 52px;
    color: #007d31;
    background-color: white;
    float: right;
`;

const Dimmer = styled.div`
    position: fixed;
    z-index: 110;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const toUp = keyframes`
0% { transform: translateY(150vh) }
100% { transform: translateY(0vh) }
`;

const toDown = keyframes`
  0% { transform: translateY(0) }
  100% { transform: translateY(150vh) }
`;

const ModalContainer = styled.div`
    position: fixed;
    top: 68px;
    background-color: #fff;
    width: 100%;
    min-height: 50%;
    padding: 0 16px 10px;
    transition: bottom 0.3s ease-out;
    z-index: 498;

    max-width: 600px;

    //animation: ${toDown} 0.3s ease-in-out;

    &.active {
        animation: ${toUp} 0.3s ease-in-out;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__input-container input {
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid #ccc;
        font-size: 16px;
    }

    .react-datepicker {
        font-size: 14px;
        margin-top: ${({ path }) => (path.includes("/tab") ? "90px" : "70px")};
    }

    .react-datepicker__header {
        position: relative;
        padding-top: 40px;
    }

    .react-datepicker__day-name {
        margin: 0 !important;
        width: 44px !important;
        height: 44px !important;
    }

    .react-datepicker__day {
        margin: 0 !important;
        width: 44px !important;
        height: 44px !important;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: #f6faed;
        border-radius: 0;
        color: #222222;
    }

    .react-datepicker__day--selected {
        background-color: #00913a !important;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        color: white;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: #00913a !important;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        color: white;
    }

    /* Custom styles to move navigation buttons to the right and month-year view to the left */
    .react-datepicker__navigation {
        top: 55px;
    }

    .react-datepicker__navigation--previous {
        right: 60px;
        left: auto;
    }

    .react-datepicker__navigation--next {
        right: 10px;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
        display: none;
    }

    .react-datepicker__month-container {
        position: relative;
    }

    .current-date {
        position: absolute;
        font-family: Pretendard, sans-serif;
        top: 110px;
        left: 27px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        z-index: 500;
        letter-spacing: -0.01em;
        color: #848b88;
    }

    .react-datepicker__month-year-read-view {
        position: absolute;
        font-family: Pretendard, sans-serif;
        font-size: 26px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: -0.01em;
        left: 10px;
        top: 55px;
    }
`;

export default DatepickerModal;
