import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import StatusLabel from "../components/StatusLabel";
import DetailHeader from "../components/common/DetailHeader";

import IcHeaderArrow from "../assets/images/icons/ic-chevron-left.svg";

import { Button, Checkbox } from "../components/common";

import retrieveManager from "../apis/retrieveManager";
import { useSelector } from "react-redux";
import { openConfirm, closeConfirm } from "../redux/ConfirmSlice";
import { useDispatch } from "react-redux";
import IcCheck from "../assets/images/icons/ic-checkbox.svg";

const RetrieveDetail = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    if (!user || !Object.keys(user).length) {
        return <Navigate to={"/login"} />;
    }
    const navigate = useNavigate();
    const { state } = useLocation();
    const [retrieveItem, setRetrieveItem] = useState({});
    const [checkItems, setCheckItems] = useState([]);
    const retrieveLocation = {
        front_door: "현관",
        security_office: "경비실",
        etc: "",
    };

    useEffect(() => {
        // 회수품(이름, 주소 동일) 상세 조회 데이터 수신
        setRetrieveItem(state.group);
    }, []);

    const completeRetrieve = () => {
        // 회수 완료 처리 후 완료 목록 이동
        checkItems.map(async (id) => {
            await retrieveManager.completeRetrieve(id);
            await dispatch(closeConfirm());
            await navigate("/tab?status=complete");
        });
    };

    const openCompleteConfirm = () => {
        dispatch(
            openConfirm({
                title: (
                    <>
                        총 {checkItems.length} 건의 항목을
                        <br />
                        회수완료 처리 하시겠습니까?
                    </>
                ),
                text: "완료 후 취소할 수 없습니다.",
                confirmText: "확인",
                confirmFunc: () => {
                    completeRetrieve();
                },
                cancelText: "취소",
                cancelFunc: () => {
                    dispatch(closeConfirm());
                },
                // width: "515",
            })
        );
    };

    // 체크된 아이템을 담을 배열
    const handleSingleCheck = (checked, id) => {
        if (checked) {
            setCheckItems((prev) => [...prev, id]);
        } else {
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if (checked) {
            const idArray = [];
            retrieveItem.duplicate.forEach((el) => idArray.push(el.id));
            setCheckItems(idArray);
        } else {
            setCheckItems([]);
        }
    };

    const handleVocSubmit = () => {
        if (checkItems.length > 0) {
            navigate("/voc", {
                state: { item: checkItems, id: user.id },
            });
        } else {
            dispatch(
                openConfirm({
                    confirmText: "확인",
                    isSingleButton: true,
                    title: "1개 이상의 회수 정보를 체크하세요.",
                    confirmFunc: () => {
                        dispatch(closeConfirm());
                    },
                })
            );
        }
    };

    return (
        <RetrieveDetailPageContainer>
            <DetailHeader title={"Box Cycle"} action={state.action} />
            <CheckboxWrap>
                {retrieveItem.status === "request" ? (
                    <>
                        <Checkbox
                            name={"select-all"}
                            type={"checkbox"}
                            id={"check-all"}
                            onChange={(e) => handleAllCheck(e.target.checked)}
                            checked={checkItems.length === retrieveItem.count}
                        />
                        <label htmlFor="check-all">
                            전체 선택 ({checkItems.length}/{retrieveItem.count})
                        </label>
                    </>
                ) : (
                    <div>
                        <label>총 {retrieveItem.count}건</label>
                    </div>
                )}
            </CheckboxWrap>
            {retrieveItem ? (
                retrieveItem.duplicate?.map((item, index) => (
                    <DetailContentWrap key={item.id}>
                        <FlexKeyValContainer>
                            {item?.status === "request" ? (
                                <Checkbox
                                    name={`select-${item.id}`}
                                    type={"checkbox"}
                                    id={"check"}
                                    onChange={(e) =>
                                        handleSingleCheck(
                                            e.target.checked,
                                            item.id
                                        )
                                    }
                                    checked={checkItems.includes(item.id)}
                                />
                            ) : null}
                            <FlexKeyValWrap>
                                <span className="key">이름</span>
                                <p className="val">{item.user_name || "-"}</p>
                            </FlexKeyValWrap>
                            <FlexKeyValWrap>
                                <span className="key">휴대폰 번호</span>
                                <p className="val">
                                    {item.user_phone_number || "-"}
                                </p>
                            </FlexKeyValWrap>
                            <FlexKeyValWrap>
                                <span className="key">주소</span>
                                <p className="val">
                                    {item.user_address || "-"}
                                </p>
                            </FlexKeyValWrap>
                            {item.user_detail_address ? (
                                <FlexKeyValWrap>
                                    <span className="key">상세주소</span>
                                    <p className="val">
                                        {item.user_detail_address || "-"}
                                    </p>
                                </FlexKeyValWrap>
                            ) : null}
                            <FlexKeyValWrap>
                                <span className="key">반납위치</span>
                                <p className="val">
                                    {retrieveLocation[item.retrieve_location]}{" "}
                                    {item.retrieve_location_detail}
                                </p>
                            </FlexKeyValWrap>
                            {/* <FlexKeyValWrap>
                        <span className="key">우편번호</span>
                        <p className="val">
                            {retrieveItem?.user_post_code || "-"}
                        </p>
                    </FlexKeyValWrap> */}
                            {item.entry_way !== "no_common_front_door" ? (
                                <FlexKeyValWrap>
                                    <span className="key">출입정보</span>
                                    <p className="val">
                                        {item.entry_way_detail}
                                    </p>
                                </FlexKeyValWrap>
                            ) : null}
                            <FlexKeyValWrap>
                                <span className="key">제품 정보</span>
                                <p className="val strong">
                                    {item.Asset?.unique_id}
                                </p>
                            </FlexKeyValWrap>
                            {item?.status === "request" ? (
                                <ButtonWrap>
                                    {/*<Button>회수 독촉하기</Button>*/}
                                    <Button
                                        className={"large"}
                                        onClick={openCompleteConfirm}
                                    >
                                        회수 완료
                                    </Button>
                                    <Button
                                        className={"large type2"}
                                        onClick={handleVocSubmit}
                                    >
                                        VOC 접수
                                    </Button>
                                </ButtonWrap>
                            ) : null}
                        </FlexKeyValContainer>
                    </DetailContentWrap>
                ))
            ) : (
                <WrongEntranceText>잘못된 접근입니다.</WrongEntranceText>
            )}
        </RetrieveDetailPageContainer>
    );
};

const CheckboxWrap = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 18px;

    input[type="checkbox"] {
        display: inline-block;
        margin-right: 12px;
    }

    label {
        color: var(--Gray-Scale-900_-36423E, #36423e);
        font-size: 15px;
        font-weight: 600;
        line-height: 105%; /* 15.75px */
        letter-spacing: -0.01em;
    }
`;

const DetailContentWrap = styled.div`
    padding: 0 16px 16px;
`;

const WrongEntranceText = styled.p`
    margin-top: 180px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
`;

const ButtonWrap = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    gap: 8px;
    padding: 0 16px 24px;
`;

const FlexKeyValContainer = styled.div`
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(40, 53, 134, 0.08);
    border-radius: 16px;
    padding: 20px 20px 56px;

    & + & {
        margin-top: 16px;
    }
`;

const FlexKeyValWrap = styled.div`
    display: flex;
    width: 100%;
    align-items: start;
    gap: 8px;
    margin-top: 14px;
    &:first-of-type {
        margin-top: 20px;
    }

    span.key {
        flex-basis: 70px;
        color: #636e73;
        font-size: 13px;
        font-weight: 500;
        line-height: 150%;
        padding-top: 1px;
    }
    p.val {
        flex: 1;
        color: #222;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;

        /* &.strong {
            font-weight: 700;
        } */
    }
`;

const CustomStatusLabel = styled(StatusLabel)`
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
`;

const PhotoContainer = styled.label`
    position: relative;
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 72.72727272%;
    background-color: #f5f6f7;
    border-radius: 10px;
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }

    img#uploadedImg {
        position: absolute;
        top: 0;
        width: 100%;
    }

    input {
        opacity: 0;
        visibility: hidden;
        width: 1px;
        height: 1px;
        position: absolute;
    }

    img.photo-icon {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const RetrieveDetailPageContainer = styled.div`
    overflow: auto;
    height: 100dvh;
    background: #fafafa;
    padding-bottom: 100px;

    h1 {
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }
`;
export default RetrieveDetail;
