import React from "react";
import styled from "styled-components";

import ImgWorker from "../assets/images/icons/ic-worker.svg";

// ------------- 사용 안함 ------------- //
const WorkerIcon = () => {
    return (
        <IconWrap>
            <img src={ImgWorker} alt="" />
        </IconWrap>
    );
};

const IconWrap = styled.div`
    border: 1px solid rgba(242, 103, 0, 0.8);
    width: 78px;
    height: 78px;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        display: block;
        pointer-events: none;
    }
`;

export default WorkerIcon;
