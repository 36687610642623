const BlBeacon = ({ color }) => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7 17.5L17.5 7.5L12.7381 3V21L17.5 16.5L7 6.5"
                stroke={color}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

BlBeacon.defaultProps = {
    color: "#ABB4B9",
};
export default BlBeacon;
