import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import CountTab from "../CountTab";
import { useSelector } from "react-redux";

const Header = () => {
    const user = useSelector((state) => state.auth.user);

    const location = useLocation();
    // QR 및 비콘 인식 오류 페이지 헤더 숨기기
    if (location.pathname === "/tab/qr" || location.pathname === "/tab/beacon")
        return null;

    return (
        <>
            <HeaderContainer>
                <NameWrap>
                    <span className="company">{user.partner_name}</span>
                    <span className="name">{user.nick_name}</span>
                </NameWrap>
                <CountTab />
            </HeaderContainer>
        </>
    );
};

const HeaderContainer = styled.div`
    width: 100%;
    background-color: #ecf5e0;
    padding: 21px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NameWrap = styled.div`
    span.company {
        display: block;
        color: rgba(0, 96, 63, 0.7);
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 15.4px */
    }
    span.name {
        color: var(--Primary-700_-006828, #006828);
        display: block;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 25.2px */
    }
`;

export default Header;
